// this is a route for devs to write and test smaller chunks

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDataContext } from 'src/context/DataContext';
import { isSupabaseDatabase } from 'core/dbs/supabaseDb';
import { createUUID } from 'core/utils/notes';

const DevWrapper = styled.div`
  padding: 2rem;
  font-family: monospace;
  white-space: pre-wrap;
`;

const StatusMessage = styled.div<{ isError?: boolean }>`
  margin: 1rem 0;
  padding: 1rem;
  background-color: ${({ isError }) => (isError ? '#ffebee' : '#e8f5e9')};
  border-radius: 4px;
`;

const DevRoute: React.FC = () => {
  const { annoteDB } = useDataContext();
  const [status, setStatus] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const processedMessages = useRef<Set<string>>(new Set());

  const addStatus = (message: string): void => {
    if (!processedMessages.current.has(message)) {
      processedMessages.current.add(message);
      setStatus((prev) => [...prev, message]);
    }
  };

  useEffect(() => {
    const dev = async (): Promise<void> => {
      if (isProcessing) return;
      setIsProcessing(true);

      if (process.env.NODE_ENV !== 'development') {
        setError('This route is only available in development mode');
        return;
      }

      if (!annoteDB) {
        setError('Database not initialized');
        return;
      }

      // if you require supabase.
      if (!isSupabaseDatabase(annoteDB)) {
        setError('Database is not Supabase and supabase is required for this operation');
        return;
      }

      try {
        // check auth
        const session = await annoteDB.db.auth.getSession();
        const userId = session.data.session?.user.id;

        if (!userId) {
          setError('No user logged in');
          return;
        }
        const copyUrl = `https://dev.annote.io/devnotetest`;

        // delete any existing notes with the same url
        const { data: updatedData, error: updateError } = await annoteDB.db
          .from('Notes')
          .update({ deletedAt: new Date().toISOString() })
          .eq('url', copyUrl)
          .is('deletedAt', null)
          .select();
        if (updateError) {
          setError(`Error updating existing note for soft delete: ${updateError.message}`);
          return;
        }
        addStatus(`Soft deleted ${updatedData?.length} existing test notes`);

        addStatus('Copy a source note...');

        const sources = await annoteDB.searchNotes('', ['source'], 1);
        if (sources.length === 0) {
          setError('No source notes found');
          return;
        }

        const source = sources[0];
        addStatus(`Got Source note: ${source.title}`);

        if (source.deletedAt) {
          setError('Source note is deleted - search results should be filtered on that');
          return;
        }

        const copyTitle = `DEV COPY of ${source.title}`;

        const copyId = createUUID();
        const copySource = {
          ...source,
          title: copyTitle,
          url: copyUrl,
          id: copyId,
          userId,
        };

        const { error: insertError } = await annoteDB.db.from('Notes').insert(copySource);
        if (insertError) {
          setError(`Error inserting DEV COPY: ${insertError.message}`);
          return;
        }

        addStatus(`Inserted DEV COPY of ${source.title} with id ${copyId}`);

        const confirmed = await annoteDB.getNoteById(copyId);

        if (!confirmed) {
          setError('Failed to confirm DEV COPY');
          return;
        }
        addStatus(`Confirmed DEV COPY: ${confirmed.title}`);

        // pause 2 seconds to let the document_sections be created
        await new Promise<void>((resolve) => {
          setTimeout(resolve, 2000);
        });

        // confirm that there are some document_sections made
        const supabase = annoteDB.db;
        const { data: documentSections, error: documentSectionsError } = await supabase
          .from('document_sections')
          .select('*')
          .eq('document_id', copyId);

        if (documentSectionsError) {
          setError(`Error getting document sections: ${documentSectionsError.message}`);
          return;
        }

        if (documentSections.length === 0) {
          setError('No document sections found');
          return;
        }
        addStatus(`Confirmed ${documentSections.length} document sections`);

        const updatedNote = { ...confirmed, title: `Updated ${confirmed.title}` };
        addStatus(`Saving an updated Note ${updatedNote.title}`);
        await annoteDB.updateNotes([updatedNote]);
        addStatus(`Saved an updated Note ${updatedNote.title}`);

        // pause 2 seconds to let the document_sections be created
        await new Promise<void>((resolve) => {
          setTimeout(resolve, 2000);
        });

        // confirm that there are some document_sections made
        const { data: newDocumentSections } = await supabase
          .from('document_sections')
          .select('*')
          .eq('document_id', copyId);

        if (!newDocumentSections) {
          setError('No new document sections returned from supabase');
          return;
        }
        if (newDocumentSections.length === 0) {
          setError('No new document sections found');
          return;
        }
        addStatus(`Confirmed ${newDocumentSections.length} new document sections`);

        // confirm that the new document_sections have all unique ids from the old ones
        const oldIds = documentSections.map((section) => section.id);
        const newIds = newDocumentSections.map((section) => section.id);
        const uniqueNewIds = new Set(newIds);
        if (oldIds.length !== uniqueNewIds.size) {
          setError('New document sections have duplicate ids');
          return;
        }

        addStatus('All new document sections have unique ids');
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error occurred');
      }
    };

    dev();
  }, [annoteDB, isProcessing]);

  return (
    <DevWrapper>
      <h1>Dev Tools - Make document sections</h1>
      {status.map((msg, i) => (
        <StatusMessage key={`${msg}-${i}`}>{msg}</StatusMessage>
      ))}
      {error && <StatusMessage isError>{error}</StatusMessage>}
    </DevWrapper>
  );
};

export default DevRoute;
