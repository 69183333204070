// this is a route for devs to write and test smaller chunks

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { getRelevantDocumentSectionsTool } from 'src/db/AnnI/tools/getRelevantDocumentSections';

const DevWrapper = styled.div`
  padding: 2rem;
  font-family: monospace;
  white-space: pre-wrap;
`;

const StatusMessage = styled.div<{ isError?: boolean }>`
  margin: 1rem 0;
  padding: 1rem;
  background-color: ${({ isError }) => (isError ? '#ffebee' : '#e8f5e9')};
  border-radius: 4px;
`;

const Form = styled.form`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-family: monospace;
  flex: 1;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-family: monospace;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const DevRoute: React.FC = () => {
  const [status, setStatus] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('Circlemind');
  const processedMessages = useRef<Set<string>>(new Set());

  const addStatus = (message: string): void => {
    if (!processedMessages.current.has(message)) {
      processedMessages.current.add(message);
      setStatus((prev) => [...prev, message]);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (isProcessing) return;
    setIsProcessing(true);
    setError(null);
    setStatus([]);
    processedMessages.current.clear();

    try {
      // Direct API call
      addStatus('Testing direct API call...');
      const baseUrl = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;

      const token = import.meta.env.VITE_SUPABASE_KEY;
      console.log('token is', token);
      const startFetch = performance.now();
      const response = await fetch(`${baseUrl}/getRelevantDocumentSections`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ query, threshold: 0.7, limit: 10 }),
      });

      const directResult = await response.json();
      addStatus(`Direct API result: ${JSON.stringify(directResult, null, 2)}`);
      const endFetch = performance.now();
      addStatus(`Direct API call took ${(endFetch - startFetch) / 1000} seconds`);
      // Langchain tool call
      addStatus('\nTesting Langchain tool...');
      const startTool = performance.now();
      const toolResult = await getRelevantDocumentSectionsTool.call({
        query,
        threshold: 0.7,
        limit: 10,
      });
      addStatus(`Langchain tool result: ${toolResult}`);
      const endTool = performance.now();
      addStatus(`Langchain tool took ${(endTool - startTool) / 1000} seconds`);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <DevWrapper>
      <h1>Dev Tools - RAG Request Tester</h1>
      <Form onSubmit={handleSubmit}>
        <Input type="text" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter search query" />
        <Button type="submit" disabled={isProcessing}>
          {isProcessing ? 'Processing...' : 'Test RAG'}
        </Button>
      </Form>
      {status.map((msg, i) => (
        <StatusMessage key={`${msg}-${i}`}>{msg}</StatusMessage>
      ))}
      {error && <StatusMessage isError>{error}</StatusMessage>}
    </DevWrapper>
  );
};

export default DevRoute;
