import styled from 'styled-components';
import { colors, text } from 'core/styles';
import { Note } from 'core/types';

export interface MenuProps {
  note: Note;
  closeMenu?: () => void;
}

export const MenuContainer = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 4px;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  min-width: 200px;
  z-index: 1000;
`;

export const MenuItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.disabled ? colors.text.placeholder : colors.text.primary)};
  font-size: 14px;
  gap: 12px;

  &:hover {
    background-color: ${(props) => (props.disabled ? 'transparent' : colors.bg.surface)};
  }
`;

export const MenuInput = styled.input`
  flex: 1;
  padding: 8px 12px;
  padding-right: 40px;
  border: 1px solid ${colors.borders.input.primary};
  border-radius: 4px;
  font-family: ${text.family.primary};
  font-size: ${text.size.primary};
  color: ${colors.text.primary};

  &::placeholder {
    color: ${colors.text.placeholder};
  }

  &:hover {
    border-color: ${colors.borders.input.selected};
  }

  &:focus {
    outline: none;
    border-color: ${colors.borders.input.selected};
  }
`;

export const ICON_SIZE = '16px';
