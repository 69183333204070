import { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { Source, Annote, Takeaway } from 'core/types';
import { useDragging } from 'src/context/DraggingContext';
import { colors } from 'core/styles/colors';
import { ListTakeawayCard } from './byType/takeaway/ListTakeaway';
import { ListSourceCard } from './byType/source/ListSource';
import { ListAnnoteCard } from './byType/annote/ListAnnote';
import { CardProps } from './Card';
// Assuming CardProps is defined in Card.tsx
export const CardList = styled.ol`
  list-style: none;
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: 0;
`;

const ListCardOutline = styled.div<{ isDragging: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.bg.primary};
  min-height: 2rem;
  user-select: none;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  box-shadow: 0px 1px 2px 0px rgba(15, 15, 15, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #f5f5f5;
`;

export const ListCard = forwardRef<HTMLDivElement, CardProps & { disabled?: boolean }>(
  ({ note, disabled = false }, forwardedRef) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    const { setDraggingNoteId, draggingNoteId } = useDragging();

    const [{ isDragging }, dragRef] = useDrag({
      type: 'LISTCARD',
      item: note,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    useEffect(() => {
      if (isDragging) {
        setDraggingNoteId(note.id);
      } else if (draggingNoteId === note.id) {
        setDraggingNoteId(null);
      }
    }, [isDragging, setDraggingNoteId, draggingNoteId, note.id]);

    useImperativeHandle(forwardedRef, () => internalRef.current as HTMLDivElement);

    dragRef(internalRef);

    return (
      <ListCardOutline ref={internalRef} id={`listcard-${note.id}`} isDragging={isDragging} disabled={disabled}>
        {note.type === 'takeaway' && <ListTakeawayCard takeaway={note as Takeaway} />}
        {note.type === 'source' && <ListSourceCard source={note as Source} />}
        {note.type === 'annote' && <ListAnnoteCard annote={note as Annote} />}
      </ListCardOutline>
    );
  },
);

export default ListCard;
