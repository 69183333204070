/* eslint-disable max-len */
import { z } from 'zod';
import { DynamicStructuredTool } from '@langchain/core/tools';
import { createNote, summarizeNote } from 'core/utils/notes';
import type { BrainContextType } from 'src/context/BrainContext';
import type { ShownIdsContextType } from 'src/context/ShownContext';
import type { Tool } from '@langchain/core/tools';
import type { Note, NoteDetails, TypeOfNote, UUID } from 'core/types';
import { isSource, isAnnote } from 'core/types';
import { extractFactualNotes } from './extractFactualNotes';
import { getRelevantDocumentSectionsTool } from './getRelevantDocumentSections';

const verboseToolCalls = true;

// Translator for the AI language to the actual database type
const typeTranslator = {
  article: 'source',
  highlight: 'annote',
  note: 'takeaway',
};

// Schema definitions
export const schemas = {
  searchDocuments: z.object({
    query: z.string().describe('The search query string to find relevant documents'),
    types: z
      .array(z.enum(['article', 'note', 'highlight']))
      .describe('The types of documents to search for (optional).'),
    limit: z.number().describe('The maximum number of documents to return (optional)'),
  }),

  noteId: z.object({
    noteId: z.string().describe('The ID (UUID) of the note'),
  }),

  createNote: z.object({
    title: z.string().describe('The title of the new document of type note'),
    content: z.string().optional().describe('The content of the new note in markdown format (optional)'),
    createdFromId: z
      .string()
      .nullable()
      .default(null)
      .describe('ID of the parent note if this is derived from another note'),
  }),

  calculator: z.object({
    operation: z.enum(['add', 'subtract', 'multiply', 'divide']).describe('The type of operation to execute.'),
    number1: z.number().describe('The first number to operate on.'),
    number2: z.number().describe('The second number to operate on.'),
  }),

  documentIds: z.object({
    documentIds: z.array(z.string()).describe('A list of document IDs (UUIDs)'),
  }),

  extractFactualNotes: z.object({
    sourceId: z.string().describe('The ID (UUID) of the source document to extract facts from'),
    noteCount: z.number().optional().default(3).describe('Number of factual notes to create (default: 3)'),
  }),
};

// Interface for dependencies needed by tools
interface ToolDependencies {
  brain: BrainContextType;
  shown: ShownIdsContextType;
  focusedDocumentId: UUID | null;
}

// Tool factory functions
export const createTools = ({ brain, shown, focusedDocumentId }: ToolDependencies): Tool[] => {
  const getDocumentsByIdsTool = new DynamicStructuredTool({
    name: 'get_documents_by_ids',
    description: `Get the full data of a list of documents by their ids. Returns both found documents 
      and a list of any IDs that couldn't be found. This is useful to hydrate the summarized results 
      returned from search or other tools.  Notes newly suggested by AI will not be listed.`,
    schema: schemas.documentIds,
    func: async ({ documentIds }) => {
      const result = {
        found: [] as Note[],
        notFound: [] as string[],
      };

      // first check if these documents are in the surfaceNoteMap (faster)
      const surfaceMatches = documentIds.map((id) => {
        const document = shown.surfaceNoteMap[id];
        if (document) {
          result.found.push(document);
          return true;
        }
        return false;
      });

      // For any IDs not found in surface map, try getting from brain
      const missingIds = documentIds.filter((_, index) => !surfaceMatches[index]);
      if (missingIds.length > 0) {
        const noteMap = await brain.getNoteMapByIds(missingIds);
        missingIds.forEach((id) => {
          if (noteMap[id]) {
            result.found.push(noteMap[id]);
          } else {
            result.notFound.push(id);
          }
        });
      }

      if (verboseToolCalls) {
        console.log('get_documents_by_ids results:', {
          foundCount: result.found.length,
          notFoundCount: result.notFound.length,
          notFoundIds: result.notFound,
        });
      }

      return JSON.stringify(result);
    },
  });

  const keywordSearchDocumentsTool = new DynamicStructuredTool({
    name: 'keyword_search_documents',
    description: `Keyword search through documents using a query string.  
    This returns just the title, type, and id of the documents, 
      and they must be hydrated with the get_documents_by_ids tool if you need more information.  
      This returns a lot of data, only use it if you have a specific search term you need to lookup for the user.`,
    schema: schemas.searchDocuments,
    func: async ({ query, types, limit }) => {
      const translatedTypes = types.map((type) => typeTranslator[type]);

      const fullResults = await brain.searchNotes(query, translatedTypes as TypeOfNote[], limit || 10);
      const summarizedResults = fullResults.map(summarizeNote);
      if (verboseToolCalls) {
        console.log('keyword_search_documents tool called with:', { query, types, limit });
        console.log('keyword_search_documents tool returned:', summarizedResults);
      }
      return JSON.stringify(summarizedResults);
    },
  });

  const getFocusedDocumentTool = new DynamicStructuredTool({
    name: 'get_focused_document',
    description: `Get information about the currently active document that the user is reading or editing right now. 
      Returns the complete document data, or null if no document is currently focused.`,
    schema: z.object({}),
    func: async () => {
      if (verboseToolCalls) {
        console.log('get_focused_document tool called with:', { focusedDocumentId });
      }
      if (!focusedDocumentId || !shown.surfaceNoteMap[focusedDocumentId]) {
        return JSON.stringify({
          status: 'no_focus',
          data: null,
        });
      }
      return JSON.stringify({
        status: 'success',
        data: shown.surfaceNoteMap[focusedDocumentId],
      });
    },
  });

  const showDocumentsTool = new DynamicStructuredTool({
    name: 'show_documents',
    description: 'Use this to show documents to the user.  It only needs the ids of the documents to show.',
    schema: schemas.documentIds,
    func: async ({ documentIds }) => {
      // The AI can screw up here, so lets be sure that all these ids exists in the brain
      const noteMap = await brain.getNoteMapByIds(documentIds);
      if (Object.keys(noteMap).length !== documentIds.length) {
        const missingIds = documentIds.filter((id) => !noteMap[id]);
        return JSON.stringify({
          status: 'error',
          message: `Unable to show documents: ${missingIds.join(', ')} not found`,
        });
      }

      shown.show(documentIds);
      if (verboseToolCalls) {
        console.log('show_documents tool called with:', { documentIds });
        console.log('show_documents tool returned:', `Documents ${documentIds.join(', ')} are now displayed`);
      }
      return `Documents ${documentIds.join(', ')} are now displayed`;
    },
  });

  const updateDocumentTool = new DynamicStructuredTool({
    name: 'update_document',
    description: 'Update/edit a document.  This will only work for documents of type "note"',
    schema: z.object({
      id: z.string().describe('The ID (UUID) of the document'),
      title: z.string().optional().describe('The updated title of the document (optional)'),
      content: z.string().optional().describe('The updated content of the document in markdown format (optional)'),
    }),
    func: async ({ id, title, content }) => {
      let document = shown.surfaceNoteMap[id];
      if (!document) {
        document = (await brain.getNoteMapByIds([id]))[id];
      }
      if (!document) {
        return JSON.stringify({
          status: 'error',
          message: `Unable to update document: ${id} not found`,
        });
      }
      if (document.type !== 'takeaway') {
        const reversedType = Object.keys(typeTranslator).find(
          (key) => typeTranslator[key as keyof typeof typeTranslator] === document.type,
        );
        return JSON.stringify({
          status: 'error',
          message: `Unable to update document: ${id} is not of type note it's a ${reversedType}`,
        });
      }
      document.title = title || document.title;
      document.value = content || document.value;
      brain.updateNotes([document]);
      shown.show(document.id);
      return JSON.stringify({
        status: 'success',
        message: `Updated document: ${id}`,
      });
    },
  });

  const linkDocumentsTool = new DynamicStructuredTool({
    name: 'link_documents',
    description:
      'Link two documents together.  This will create a link from the first document to the second document.',
    schema: z.object({
      linkFromId: z.string().describe('The ID (UUID) of the document to link from'),
      linkToId: z.string().describe('The ID (UUID) of the document to link to'),
    }),
    func: async ({ linkFromId, linkToId }) => {
      // first check if these notes are in the surfaceNoteMap (faster)
      let linkFrom = shown.surfaceNoteMap[linkFromId];
      let linkTo = shown.surfaceNoteMap[linkToId];
      if (!linkFrom || !linkTo) {
        const noteMap = await brain.getNoteMapByIds([linkFromId, linkToId]);
        linkFrom = noteMap[linkFromId];
        linkTo = noteMap[linkToId];
      }
      if (verboseToolCalls) {
        console.log(
          'link_documents tool called with:',
          { linkFromId, linkToId },
          'linking documents:',
          linkFrom,
          linkTo,
        );
      }

      if (!linkFrom || !linkTo) {
        return JSON.stringify({
          status: 'error',
          message: `Unable to link documents: ${!linkFrom ? linkFromId : linkToId} not found`,
          data: null,
        });
      }
      const [updatedLinkFrom, updatedLinkTo] = brain.linkNotes(linkFrom, linkTo);
      if (verboseToolCalls) {
        console.log('link_documents tool linked:', { updatedLinkFrom, updatedLinkTo });
      }
      shown.show([updatedLinkFrom.id, updatedLinkTo.id]);

      return JSON.stringify({
        status: 'success',
        message: `Linked document ${linkFromId} to document ${linkToId}`,
      });
    },
  });

  const hideDocumentsTool = new DynamicStructuredTool({
    name: 'hide_documents',
    description: 'Hide multiple documents in the interface',
    schema: schemas.documentIds,
    func: async ({ documentIds }) => {
      // The AI can screw up here, so lets be sure that all these ids exists in the brain
      const noteMap = await brain.getNoteMapByIds(documentIds);
      if (Object.keys(noteMap).length !== documentIds.length) {
        const missingIds = documentIds.filter((id) => !noteMap[id]);
        return JSON.stringify({
          status: 'error',
          message: `Unable to hide documents: ${missingIds.join(', ')} not found`,
        });
      }
      shown.hide(documentIds);
      if (verboseToolCalls) {
        console.log('hide_documents tool called with:', { documentIds });
        console.log('hide_documents tool returned:', `Documents ${documentIds.join(', ')} are now hidden`);
      }
      return `Documents ${documentIds.join(', ')} are now hidden`;
    },
  });

  const createNoteTool = new DynamicStructuredTool({
    name: 'create_note',
    description: `Creates and shows a new document of type note (aka takeaway) with specified title and optional content in markdown format.   
      Linking works the same as markdown, but to link to an internal note use the format [text to link][{documentId}]. DONOT show or check for the note after this.  It will already be displayed to the usser as a suggestion to accept.`,
    schema: schemas.createNote,
    func: async ({ title, content, createdFromId }) => {
      const newNote = createNote(title, 'takeaway', content, createdFromId || undefined);
      if (!newNote.details) {
        newNote.details = {} as NoteDetails;
      }
      newNote.details.generator = 'ai';
      newNote.isSuggestion = true; // notes from AI are always suggestions first
      brain.updateNotes([newNote]);
      shown.show(newNote.id);
      if (verboseToolCalls) {
        console.log('create_note tool called with:', { title, content, createdFromId });
        console.log('create_note tool returned:', `Created new Note Document: ${newNote.id}`);
      }
      return `Created and showing new Note Document: ${newNote.id}`;
    },
  });

  const getShownIdsTool = new DynamicStructuredTool({
    name: 'get_shown_ids',
    description:
      'Get the ids of the currently displayed documents. To get the full document data, use get_documents_by_ids with these ids.',
    schema: z.object({}),
    func: async () => {
      if (verboseToolCalls) {
        console.log('get_shown_ids tool called with:', { shown });
      }
      return JSON.stringify(shown.shownIds);
    },
  });

  // Here as an example and just for fun - why not.
  const calculatorTool = new DynamicStructuredTool({
    name: 'calculator',
    description: 'Can perform mathematical operations.',
    schema: schemas.calculator,
    func: async ({ operation, number1, number2 }) => {
      if (operation === 'add') return `${number1 + number2}`;
      if (operation === 'subtract') return `${number1 - number2}`;
      if (operation === 'multiply') return `${number1 * number2}`;
      if (operation === 'divide') return `${number1 / number2}`;
      throw new Error('Invalid operation.');
    },
  });

  const extractFactualNotesTool = new DynamicStructuredTool({
    name: 'extract_factual_notes',
    description: `This tool is used to extract factual notes from a source document and its highlights.  
    It will automatically create and then show the notes.
    DONOT show notes after this tool, it has already done that.
    `,
    schema: schemas.extractFactualNotes,
    func: async ({ sourceId, noteCount }) => {
      // Get the source document
      let source = shown.surfaceNoteMap[sourceId];
      if (!source) {
        const noteMap = await brain.getNoteMapByIds([sourceId]);
        source = noteMap[sourceId];
      }
      if (!source) {
        return JSON.stringify({
          status: 'error',
          message: `Source document ${sourceId} not found`,
        });
      }
      if (!isSource(source)) {
        return JSON.stringify({
          status: 'error',
          message: `Source document ${sourceId} is not of type Source`,
        });
      }

      // Get relevant highlights if specified
      const highlightMap = await brain.getNoteMapByIds(source.links);
      const highlights = Object.values(highlightMap).filter((doc) => isAnnote(doc));

      // Get the notes from the LLM
      const notesFromLLM: { title: string; content: string }[] = await extractFactualNotes(
        brain.customFetch,
        source,
        highlights,
        noteCount,
      );

      const createdNotes: Note[] = [];
      notesFromLLM.forEach((llmNote) => {
        const note = createNote(llmNote.title, 'takeaway', llmNote.content, source.id);
        note.details = {
          ...note.details,
          generator: 'ai',
          tool: 'extract_factual_notes',
        };
        note.isSuggestion = true; // notes from AI are always suggestions first
        note.links = [source.id]; // once accepted, it will also store the backlink
        createdNotes.push(note);
      });

      // Save and show the notes
      brain.updateNotes(createdNotes);
      const createdNoteIds = createdNotes.map((note) => note.id);
      shown.show(createdNoteIds);

      return JSON.stringify({
        status: 'success',
        message: `Created and displayed ${createdNotes.length} factual notes from ${source.title}`,
        noteIds: createdNoteIds,
      });
    },
  });

  return [
    getDocumentsByIdsTool,
    getFocusedDocumentTool,
    linkDocumentsTool,
    updateDocumentTool,
    getShownIdsTool,
    getRelevantDocumentSectionsTool,
    showDocumentsTool,
    hideDocumentsTool,
    createNoteTool,
    keywordSearchDocumentsTool,
    extractFactualNotesTool,
    calculatorTool,
  ] as unknown as Tool[];
};
