import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ShownIdsContext } from 'src/context/ShownContext';
import { Card } from 'src/components/Cards/Card';
import { useBrainContext } from 'src/context/BrainContext';
import { Note } from 'core/types';

const MAX_DEPTH = 4;
const MIN_DEPTH = 0;

const Surface = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  background-size: 10px 10px;
  background-position: 0 0;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 40rem;
  margin: 0 2rem;
  max-height: 100vh; /* Limit height to viewport */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 1rem; /* Add some padding for better appearance */
  position: relative; /* Added for sticky header positioning context */
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 2rem;
  margin-top: 8rem;
`;

const ColumnContainer = styled(ColumnGroup)`
  flex-direction: row;
`;

const PinnedColumn = styled(ColumnGroup)`
  width: fit-content;
  align-items: center;
`;

const CardWrapper = styled.div`
  padding-bottom: 2rem;
`;

const GroupHeader = styled.h2`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled.button`
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
`;

const LinkDepthSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BacklinkDepthSelector = styled(LinkDepthSelector)`
  margin-left: 1rem;
`;

const FocusSurface: React.FC = () => {
  const { shownIds, clear, show, surfaceNoteMap, updateSize } = useContext(ShownIdsContext);
  const { getNoteMapByIds } = useBrainContext();
  const [linkDepth, setLinkDepth] = useState(1);
  const [backlinkDepth, setBacklinkDepth] = useState(1);
  const [resolvedLinksByDepth, setResolvedLinksByDepth] = useState<Note[][]>([]);
  const [resolvedBacklinksByDepth, setResolvedBacklinksByDepth] = useState<Note[][]>([]);

  const focusedNote = useMemo(() => surfaceNoteMap[shownIds[0]], [surfaceNoteMap, shownIds]);

  // const links = useMemo(
  //   () => focusedNote?.links.map((linkId) => surfaceNoteMap[linkId]).filter((n) => n),
  //   [focusedNote?.links, surfaceNoteMap],
  // );

  // const backlinks = useMemo(
  //   () => focusedNote?.backlinks.map((linkId) => surfaceNoteMap[linkId]).filter((n) => n),
  //   [focusedNote?.backlinks, surfaceNoteMap],
  // );

  const getNotesByDepth = useCallback(
    async (linkOrBacklink: 'link' | 'backlink', depth: number): Promise<Note[][]> => {
      const notesByDepth: Note[][] = [];
      for (let i = 0; i < depth; i += 1) {
        const previousNotes = i === 0 ? [focusedNote] : notesByDepth[i - 1];

        const connectionIds = previousNotes.flatMap((note) =>
          linkOrBacklink === 'link' ? note.links : note.backlinks,
        );
        // TODO - we don't have graph style querying yet so this will have to do for now
        // eslint-disable-next-line no-await-in-loop
        const connections = await getNoteMapByIds(connectionIds);
        notesByDepth.push(Object.values(connections));
      }
      return notesByDepth;
    },
    [focusedNote],
  );

  useEffect(() => {
    const fetchLinks = async (): Promise<void> => {
      const links = await getNotesByDepth('link', linkDepth);
      setResolvedLinksByDepth(links);
    };
    fetchLinks();
  }, [getNotesByDepth, linkDepth]);

  useEffect(() => {
    const fetchBacklinks = async (): Promise<void> => {
      const backlinks = await getNotesByDepth('backlink', backlinkDepth);
      setResolvedBacklinksByDepth(backlinks);
    };
    fetchBacklinks();
  }, [getNotesByDepth, backlinkDepth]);

  const pinCard = (cardId: string): void => {
    clear();
    show([cardId]);
    console.log('focusOnCard', cardId);
    updateSize(cardId, 'LARGE');
  };

  return (
    <Surface id="surface">
      <ColumnGroup>
        <GroupHeader>
          Links
          <LinkDepthSelector>
            <Button onClick={() => setLinkDepth(Math.min(linkDepth + 1, MAX_DEPTH))}>+</Button>
            <Button onClick={() => setLinkDepth(Math.max(linkDepth - 1, MIN_DEPTH))}>-</Button>
          </LinkDepthSelector>
        </GroupHeader>
        <ColumnContainer>
          {resolvedLinksByDepth.map((links, index) => (
            <CardColumn key={`link-column-${index}`}>
              {links.reverse().map((link) => (
                <CardWrapper key={`link-wrapper-${link.id}`} onDoubleClick={() => pinCard(link.id)}>
                  <Card key={`link-${link.id}`} note={link} />
                </CardWrapper>
              ))}
            </CardColumn>
          ))}
        </ColumnContainer>
      </ColumnGroup>
      <PinnedColumn>
        <GroupHeader>Focused Card</GroupHeader>
        {focusedNote && <Card key={focusedNote.id} note={focusedNote} />}
      </PinnedColumn>
      <ColumnGroup>
        <GroupHeader>
          Backlinks
          <BacklinkDepthSelector>
            <Button onClick={() => setBacklinkDepth(Math.min(backlinkDepth + 1, MAX_DEPTH))}>+</Button>
            <Button onClick={() => setBacklinkDepth(Math.max(backlinkDepth - 1, MIN_DEPTH))}>-</Button>
          </BacklinkDepthSelector>
        </GroupHeader>
        <ColumnContainer>
          {resolvedBacklinksByDepth.map((backlinks, index) => (
            <CardColumn key={`backlink-column-${index}`}>
              {backlinks.map((backlink) => (
                <CardWrapper key={`backlink-wrapper-${backlink.id}`} onDoubleClick={() => pinCard(backlink.id)}>
                  <Card key={`backlink-${backlink.id}`} note={backlink} />
                </CardWrapper>
              ))}
            </CardColumn>
          ))}
        </ColumnContainer>
      </ColumnGroup>
    </Surface>
  );
};

export default FocusSurface;
