import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { createEditor, EditorView } from 'core/components/proseEditor';
import { getMarkdownFromView } from 'core/components/proseEditor/utils';
import { Note } from 'core/types';
import { useDataContext } from 'src/context/DataContext';
import { initialize as initializeProseMirror } from 'core/components/proseEditor/db';
import styled from 'styled-components';
import { colors, text } from 'core/styles';
import { useBrainContext } from 'src/context/BrainContext';
import { FocusContext } from 'src/context/FocusContext';
import { suggestLinkMenu } from 'core/components/proseEditor/suggestLinkMenu';

const EditorContainer = styled.span<{ isInline: boolean }>`
  ${({ isInline }) =>
    !isInline &&
    `
      display: flex;
      flex-direction: column;
      height: 100%; // Take full height of parent
      flex: 1; // Add this to make it grow within flex parents
      width: 100%; // Ensure full width
      min-height: 100%; // Ensure it fills container
      position: relative;
    `}

  .ProseMirror {
    outline: none;
    white-space: pre-wrap; /* Preserve spaces and line breaks */
    word-wrap: break-word; /* Allow long words to be broken */
    ${({ isInline }) =>
      !isInline &&
      `
          flex: 1; // Fill remaining space
          min-height: 100%; // Ensure it fills container
        `}
  }

  .ProseMirror.read-only {
    cursor: default;
    pointer-events: none;
  }

  .ProseMirror.empty[data-placeholder]:before {
    content: attr(data-placeholder);
    font-family: ${text.family.primary};
    font-size: ${text.size.primary};
    font-weight: ${text.weight.regular};
    color: ${colors.text.placeholder};
    pointer-events: none;
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
  }

  .ProseMirror.ProseMirror-focused.empty[data-focusedplaceholder]:before {
    content: attr(data-focusedplaceholder);
  }
`;

export const ProseEditor: React.FC<{
  note: Note;
  placeholder?: string;
  focusedPlaceholder?: string;
  readOnly?: boolean;
  isInline?: boolean;
}> = ({ note, placeholder = '', focusedPlaceholder = '', readOnly = false, isInline = true }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const editorViewRef = useRef<EditorView | null>(null);
  const { focusedNoteId, focusedTarget } = useContext(FocusContext);
  const { annoteDB } = useDataContext();

  const { updateNotes } = useBrainContext();

  const handleBlur = useCallback(async (): Promise<void> => {
    if (!editorViewRef.current) return;
    if (suggestLinkMenu.recentlyClicked) return; // not a real blur if they clicked on a suggestion
    const newMarkdown = getMarkdownFromView(editorViewRef.current);
    if (newMarkdown === note.value) return;

    // TODO - because prosemirror uses annoteDB directly, surfaceNoteMap can sometimes not be the latest...
    const latestNotes = await annoteDB?.getNotes([note.id]);
    const latestNote = latestNotes ? latestNotes[0] : note;
    // const latestNote = surfaceNoteMap[note.id] || note; // ensure we're using the latest version of the note
    console.log('blur saving note', latestNote.id, latestNote.links, latestNote);
    await updateNotes([{ ...latestNote, value: newMarkdown }]);
  }, [note, updateNotes]);

  useEffect(() => {
    if (!editorViewRef.current && editorRef.current) {
      editorViewRef.current = createEditor(editorRef.current, note, placeholder, focusedPlaceholder, readOnly);
    }
    // Cleanup on component unmount
    return () => {
      if (editorViewRef.current) {
        editorViewRef.current.destroy();
        editorViewRef.current = null;
      }
    };
  }, [note.value]); // reload the editor if the value changes

  useEffect(() => {
    if (!editorViewRef.current) return;
    if (!editorRef.current) return;
    if (focusedNoteId !== note.id) return;
    // By default, focus the editor unless they've explicitly clicked on a different target
    if (focusedTarget && !(editorRef.current as HTMLElement).contains(focusedTarget)) return;

    editorViewRef.current.focus();
  }, [focusedNoteId, note.id]);

  useEffect(() => {
    // Initialize ProseMirror when the database is available
    if (annoteDB) {
      initializeProseMirror(annoteDB);
    }
  }, [annoteDB]);

  return <EditorContainer ref={editorRef} onBlur={handleBlur} isInline={isInline} />;
};
