import styled from 'styled-components';
import { Note } from 'core/types';
import { useBrainContext } from 'src/context/BrainContext';
import { useContext } from 'react';
import { ShownIdsContext } from 'src/context/ShownContext';
import { colors } from 'core/styles';
import { ReactComponent as AcceptIcon } from 'src/components/Icon/icons/accept.svg';
import { ReactComponent as RejectIcon } from 'src/components/Icon/icons/reject.svg';
import { CardButton } from './CardButton';

const SuggestionButtonsWrapper = styled.span`
  .accept button {
    background-color: ${colors.button.accept.primary};
    border-radius: 0 4px 4px 0;
    margin-left: 2px;
  }

  .accept:hover button {
    background-color: ${colors.button.accept.hover};
  }

  .reject button {
    background-color: ${colors.button.reject.primary};
    border-radius: 4px 0 0 4px;
  }

  .reject:hover button {
    background-color: ${colors.button.reject.hover};
  }
`;

export const SuggestionButtons: React.FC<{ note: Note }> = ({ note }) => {
  const { updateNotes, deleteNotes } = useBrainContext();
  const { hide } = useContext(ShownIdsContext);
  const { getNoteMapByIds } = useBrainContext();

  const handleAccept = async (): Promise<void> => {
    const acceptedNote = { ...note };
    delete acceptedNote.isSuggestion; // remove the isSuggestion property - it can't be saved with it

    // update linked notes to include this note's backlink
    const linkedNotesMap = note.links.length > 0 ? await getNoteMapByIds(note.links) : {};
    const linkedNotes = Object.values(linkedNotesMap);
    linkedNotes.forEach((linkedNote) => {
      if (!linkedNote.backlinks.includes(note.id)) {
        linkedNote.backlinks.push(note.id);
      }
    });

    console.log('acceptedNote', acceptedNote);
    updateNotes([acceptedNote, ...linkedNotes]);
  };

  const handleReject = (): void => {
    console.log('Reject suggestion', note.id);
    hide(note.id);
    deleteNotes([note]);
  };

  return (
    <SuggestionButtonsWrapper>
      <CardButton className="reject" iconSVG={RejectIcon} onClick={handleReject} />
      <CardButton className="accept" iconSVG={AcceptIcon} onClick={handleAccept} />
    </SuggestionButtonsWrapper>
  );
};
