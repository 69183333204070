import { createContext, useContext, ReactNode, useCallback, useState } from 'react';
import { HumanMessage, AIMessage, BaseMessage } from '@langchain/core/messages';
import { AgentExecutor, createOpenAIFunctionsAgent } from 'langchain/agents';

import localPrompt from 'src/db/AnnI/prompt';
import { createTools } from 'src/db/AnnI/tools/index';
import { ChainValues } from '@langchain/core/dist/utils/types';
import { UUID } from 'core/types';
import { AgentStep } from '@langchain/core/agents';
import { createLLM } from 'src/db/AnnI/llm';
import { createUUID } from 'core/utils/notes';
import { useBrainContext } from './BrainContext';
import { ShownIdsContext } from './ShownContext';
import { FocusContext } from './FocusContext';

interface Message {
  role: 'user' | 'assistant' | 'step' | 'thinking' | 'error' | '$';
  content: string;
}

interface AIContextType {
  chat: (
    thread: UUID,
    message: string,
    callbacks?: {
      onToken?: (token: string) => void;
      onStep?: (step: AgentStep) => void;
    },
  ) => Promise<ChainValues>;
  messages: Message[];
  currentStreamingMessage: string;
  isLoading: boolean;
  input: string;
  isMinimized: boolean;
  showSteps: boolean;
  thread: UUID;
  setInput: (input: string) => void;
  setIsMinimized: (isMinimized: boolean) => void;
  setShowSteps: (showSteps: boolean) => void;
  startNewThread: () => void;
  submitMessage: (message: string) => Promise<void>;
}

const AIContext = createContext<AIContextType | null>(null);

interface AIProviderProps {
  children: ReactNode;
}

export const AIProvider: React.FC<AIProviderProps> = ({ children }) => {
  const [chatHistories, setChatHistories] = useState<Record<UUID, BaseMessage[]>>({});
  const brain = useBrainContext();
  const shown = useContext(ShownIdsContext);
  const { focusedNoteId } = useContext(FocusContext);

  // Create the list of tools available to the AI
  const tools = createTools({ brain, shown, focusedDocumentId: focusedNoteId });

  // New UI state
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState('');
  const [isMinimized, setIsMinimized] = useState(true);
  const [showSteps, setShowSteps] = useState(true);
  const [thread, setThread] = useState<UUID>(createUUID());

  const startNewThread = useCallback(() => {
    setThread(createUUID());
    setMessages([]);
  }, []);

  // test with:
  // Create a note with the title "AI is working in Annote" and some celebratory content
  const chat = useCallback(
    async (
      messageThread: UUID,
      message: string,
      callbacks?: {
        onToken?: (token: string) => void;
        onStep?: (step: AgentStep) => void;
      },
    ): Promise<ChainValues> => {
      try {
        const llm = await createLLM(brain.customFetch, callbacks);

        // Make a functions based agent for proper tool execution with OpenAI
        const agentAnnI = await createOpenAIFunctionsAgent({
          llm,
          tools,
          prompt: localPrompt,
        });
        // The executor is the one that actually runs the agent and returns the result when it's finished
        const agentExecutor = new AgentExecutor({
          agent: agentAnnI,
          tools,
          verbose: false,
          returnIntermediateSteps: true,
          callbacks: [
            {
              handleAgentAction(action) {
                const step: AgentStep = {
                  action,
                  observation: '',
                };
                callbacks?.onStep?.(step);
              },
              handleAgentEnd(output) {
                if (output.returnValues?.output) {
                  const step: AgentStep = {
                    action: {
                      tool: 'final_answer',
                      toolInput: output.returnValues.output,
                      log: output.log,
                    },
                    observation: output.returnValues.output,
                  };
                  callbacks?.onStep?.(step);
                }
              },
              // handleChainEnd(outputs) {},
            },
          ],
        });

        // Get the chat history for this thread
        const threadHistory = chatHistories[messageThread] || [];

        // Run the agent with the message and the thread-specific chat history
        const result = await agentExecutor.invoke({
          input: message,
          chat_history: threadHistory,
        });
        // Update chat history for this specific thread
        setChatHistories((prev) => ({
          ...prev,
          [messageThread]: [...(prev[messageThread] || []), new HumanMessage(message), new AIMessage(result.output)],
        }));

        return result;
      } catch (error) {
        console.error('Error in chat:', error);
        throw error;
      }
    },
    [chatHistories, tools, brain, shown],
  );

  const submitMessage = useCallback(
    async (message: string) => {
      setIsMinimized(false);
      if (!message.trim()) return;

      setMessages((prev) => [...prev, { role: 'user', content: message }]);
      setIsLoading(true);
      setCurrentStreamingMessage('');

      try {
        const result = await chat(thread, message, {
          onToken: (token: string) => {
            setCurrentStreamingMessage((prev) => prev + token);
          },
          onStep: (step: AgentStep) => {
            if (showSteps) {
              const stepRole = step.action.tool === 'final_answer' ? 'assistant' : 'step';
              let msg = '';
              if (step.action.log) {
                msg += `${step.action.log}\n`;
              }
              setMessages((prev) => [...prev, { role: stepRole, content: msg }]);
            }
          },
        });

        setCurrentStreamingMessage('');
        console.log('AI Agent result', result);
      } catch (error) {
        console.error('Error in chat:', error);
        setMessages((prev) => [...prev, { role: 'error', content: `Error in chat: ${error}` }]);
      } finally {
        setIsLoading(false);
        setInput('');
      }
    },
    [thread, chat, showSteps],
  );

  const value = {
    chat,
    messages,
    currentStreamingMessage,
    isLoading,
    input,
    isMinimized,
    showSteps,
    thread,
    setInput,
    setIsMinimized,
    setShowSteps,
    startNewThread,
    submitMessage,
  };

  return <AIContext.Provider value={value}>{children}</AIContext.Provider>;
};

export const useAIContext = (): AIContextType => {
  const context = useContext(AIContext);
  if (!context) {
    throw new Error('useAIContext must be used within an AIProvider');
  }
  return context;
};
