import React, { useState } from 'react';
import { useAIContext } from 'src/context/AIContext';
import styled from 'styled-components';
import { MenuContainer, MenuItem, MenuProps, MenuInput } from './MenuComponents';

const PromptMenuItem = styled(MenuItem)`
  background-color: inherit;
  &:hover {
    background-color: inherit;
  }
`;

const AIMenu: React.FC<MenuProps> = ({ note, closeMenu }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { submitMessage } = useAIContext();

  // [Alias prompt, full prompt]
  const examplePrompts = [
    ['Suggest notes from this', 'Suggest notes from this'],
    ['Summarize this', 'Summarize this'],
    ['Suggest other content based on this', 'Suggest other content based on this'],
    ["Show related notes I've taken", "Show related notes I've taken"],
    ['Group with similar notes', 'Group with similar notes'],
    ['Break this into multiple notes', 'Break this into multiple notes'],
  ];

  const handleExamplePromptClick = (prompt: string): void => {
    // Format the prompt to include context about the specific note
    const contextualPrompt = `Regarding the note "${note.title}" with id: ${note.id}: ${prompt}`;
    submitMessage(contextualPrompt);
    if (closeMenu) closeMenu();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  const handleInputSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleExamplePromptClick(inputValue);
  };

  return (
    <MenuContainer>
      <PromptMenuItem>
        <form onSubmit={handleInputSubmit}>
          <MenuInput placeholder="Enter your own prompt" value={inputValue} onChange={handleInputChange} />
        </form>
      </PromptMenuItem>
      {examplePrompts.map(([alias, full]) => (
        <MenuItem key={alias} onClick={() => handleExamplePromptClick(full)}>
          {alias}
        </MenuItem>
      ))}
    </MenuContainer>
  );
};

export default AIMenu;
