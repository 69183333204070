import React, { useContext } from 'react';
import { ShownIdsContext } from 'src/context/ShownContext';
import { Note } from 'core/types';
import { ReactComponent as ExpandIcon } from 'src/components/Icon/icons/expand.svg';
import { ReactComponent as MinimizeIcon } from 'src/components/Icon/icons/minimize.svg';
import { CardButton } from './CardButton';

// Toggles cards between small and large
// Sources can also be a "MEDIUM" size - that is handled in the source component with a different button.
export const SizeToggleButton: React.FC<{ note: Note }> = ({ note }) => {
  const { shownSizes, updateSize } = useContext(ShownIdsContext);

  const size = shownSizes[note.id] || 'SMALL';
  const iconSVG = size === 'LARGE' ? MinimizeIcon : ExpandIcon;

  const handleToggleSize = (): void => {
    updateSize(note.id, size === 'LARGE' ? 'MEDIUM' : 'LARGE');
  };

  return <CardButton iconSVG={iconSVG} onClick={handleToggleSize} />;
};
