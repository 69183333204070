import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Note, Source, Takeaway } from 'core/types';
import { colors } from 'core/styles';
import TakeawayCard from './byType/takeaway/Takeaway';
import { SourceCard } from './byType/source/Source';

const PREVIEW_CARD_GAP_IN_PX = 35;

type CornerPosition = 'bottom-left' | 'top-right';

const PreviewCardWrapper = styled.div`
  position: absolute;
  z-index: 1010;
  background: ${colors.bg.primary};
  box-shadow: 0px 1px 2px 0px rgba(15, 15, 15, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
`;

type PreviewCardProps = {
  note: Note;
  triggerRef: React.RefObject<HTMLDivElement>; // the element that triggers the preview
  isTriggerHovered?: boolean;
  className?: string;
  cornerPosition?: CornerPosition;
};

const PORTAL_ID = 'surface';

const PreviewCard: React.FC<PreviewCardProps> = ({
  note,
  triggerRef,
  isTriggerHovered = false,
  className,
  cornerPosition = 'top-right',
}) => {
  const [previewCoords, setPreviewCoords] = useState<{ top: number; side: number }>({
    top: 0,
    side: 0,
  });

  const setCoords = (): void => {
    // TODO consider having surfaceRef in context
    const surfaceRef = document.querySelector<HTMLDivElement>(`#${PORTAL_ID}`);
    if (!surfaceRef) throw new Error("Preview can't be rendered - Surface not found");
    if (!triggerRef.current) throw new Error("Preview can't be rendered - Trigger Ref not found");

    const surfaceRect = surfaceRef?.getBoundingClientRect() || { top: 0, left: 0 };
    // the bounding variables of the hovered object
    const hoverRect = triggerRef.current.getBoundingClientRect();
    // for default cornerPosition of top-right
    let newTop = hoverRect.top + surfaceRef.scrollTop - surfaceRect.top;
    let newSide = hoverRect.left + surfaceRef.scrollLeft + hoverRect.width + PREVIEW_CARD_GAP_IN_PX - surfaceRect.left;

    if (cornerPosition === 'bottom-left') {
      // TODO - get actual width of preview card
      newSide = hoverRect.left + surfaceRef.scrollLeft - surfaceRect.left - 35 * 8 - PREVIEW_CARD_GAP_IN_PX;
      newTop = hoverRect.top + surfaceRef.scrollTop - surfaceRect.top;
    }

    setPreviewCoords({
      top: newTop,
      side: newSide,
    });
  };

  const getPositionStyle = useCallback(() => {
    if (cornerPosition === 'bottom-left') {
      return { top: previewCoords.top, left: previewCoords.side };
    }
    return { top: previewCoords.top, left: previewCoords.side };
  }, [previewCoords, cornerPosition]);

  useEffect(() => {
    setCoords();
  }, [isTriggerHovered, triggerRef?.current]);

  const modalRoot = document.querySelector<HTMLDivElement>(`#${PORTAL_ID}`);
  if (!modalRoot) {
    return null;
  }

  const preview = (
    <PreviewCardWrapper style={getPositionStyle()} className={className}>
      {note.type === 'takeaway' && (
        <TakeawayCard key={`preview-${note.id}`} takeaway={note as Takeaway} readOnly={true} />
      )}
      {note.type === 'source' && (
        <SourceCard key={`preview-${note.id}`} source={note as Source} inPreview readOnly={true} />
      )}
    </PreviewCardWrapper>
  );

  return ReactDOM.createPortal(preview, modalRoot);
};

export default PreviewCard;
