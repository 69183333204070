import React, { useCallback, useEffect } from 'react';
import { useAIContext } from 'src/context/AIContext';
import styled from 'styled-components';
import { text, colors } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';

type MessageRole = 'user' | 'assistant' | 'step' | 'thinking' | 'error' | '$';

const Container = styled.div<{ isMinimized: boolean }>`
  position: fixed;
  bottom: 2px;
  right: 40px;
  width: ${(props) => (props.isMinimized ? '300px' : '300px')};
  background: ${(props) => (props.isMinimized ? 'transparent' : colors.bg.library)};
  border-radius: 6px;
  box-shadow: ${(props) => (props.isMinimized ? 'none' : colors.shadow.surfacenav)};
`;

const ChatHeader = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-family: ${text.family.primary};
    font-size: ${text.size.small};
    font-weight: ${text.weight.medium};
    color: ${colors.text.button};
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    color: ${colors.text.button};
    font-size: ${text.size.small};
    font-weight: ${text.weight.medium};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const MessagesContainer = styled.div`
  height: 70vh;
  overflow-y: auto;
  padding: 12px;
`;

const messageStyles = {
  user: {
    background: colors.bg.primary,
    color: colors.text.primary,
    fontSize: text.size.primary,
    fontStyle: 'normal',
  },
  assistant: {
    background: 'inherit',
    color: colors.text.primary,
    fontSize: text.size.primary,
    fontStyle: 'normal',
  },
  step: {
    background: 'inherit',
    color: colors.text.primary,
    fontSize: text.size.small,
    fontStyle: 'italic',
    prefix: 'step : ',
  },
  thinking: {
    background: 'inherit',
    color: colors.text.primary,
    fontSize: text.size.primary,
    fontStyle: 'normal',
  },
  error: {
    background: 'inherit',
    color: colors.text.error,
    fontSize: text.size.primary,
    fontStyle: 'normal',
  },
  $: {
    background: 'inherit',
    color: colors.text.primary,
    fontSize: text.size.small,
    fontStyle: 'italic',
    prefix: '$: ',
  },
};

const Message = styled.div<{ role: MessageRole }>`
  margin: 8px 0;
  padding: 8px 12px;
  border-radius: 6px;
  font-family: ${text.family.primary};

  background: ${(props) => messageStyles[props.role].background};
  color: ${(props) => messageStyles[props.role].color};
  font-size: ${(props) => messageStyles[props.role].fontSize};
  font-style: ${(props) => messageStyles[props.role].fontStyle};

  &::before {
    content: ${(props) => (props.role === 'step' ? messageStyles[props.role].prefix : '')};
  }
`;

const InputForm = styled.form`
  padding: 12px;
  display: flex;
  gap: 8px;
  position: relative;
`;

const Input = styled.input`
  flex: 1;
  padding: 8px 12px;
  padding-right: 40px;
  border: 1px solid ${colors.borders.input.primary};
  border-radius: 4px;
  font-family: ${text.family.primary};
  font-size: ${text.size.primary};
  color: ${colors.text.primary};

  &::placeholder {
    color: ${colors.text.placeholder};
  }

  &:focus {
    outline: none;
    border-color: ${colors.borders.input.selected};
  }
`;

const SendButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

interface Message {
  role: MessageRole;
  content: string;
}

export const AnnIChat: React.FC = () => {
  const {
    messages,
    currentStreamingMessage,
    isLoading,
    input,
    isMinimized,
    showSteps,
    setInput,
    setIsMinimized,
    setShowSteps,
    startNewThread,
    submitMessage,
  } = useAIContext();
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const handleMinimize = useCallback(() => {
    setIsMinimized(true);
    // closing also resets the thread
    startNewThread();
  }, [setIsMinimized, startNewThread]);

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    submitMessage(input);
  };

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Scroll when messages change or when streaming
  useEffect(() => {
    scrollToBottom();
  }, [messages, currentStreamingMessage, scrollToBottom]);

  return (
    <Container isMinimized={isMinimized}>
      {!isMinimized && (
        <>
          <ChatHeader>
            <h3>Ask AnnI</h3>
            <ButtonGroup>
              <button onClick={() => setShowSteps(!showSteps)} title="Show Details">
                <input type="checkbox" checked={showSteps} onChange={() => {}} style={{ marginRight: '4px' }} />
                Details
              </button>
              <button onClick={startNewThread}>+</button>
              <button onClick={handleMinimize}>x</button>
            </ButtonGroup>
          </ChatHeader>
          <MessagesContainer>
            {messages.map((message, index) => (
              <Message key={index} role={message.role}>
                {message.content}
              </Message>
            ))}
            {currentStreamingMessage && <Message role="assistant">{currentStreamingMessage}</Message>}
            {isLoading && !currentStreamingMessage && <Message role="thinking">...</Message>}
            <div ref={messagesEndRef} /> {/* Invisible element at the bottom */}
          </MessagesContainer>
        </>
      )}
      <InputForm onSubmit={handleSubmit}>
        {/* {focusedNoteId && <InlineCard note={surfaceNoteMap[focusedNoteId]} />} */}
        <br />
        <Input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask AnnI for help with your notes..."
          disabled={isLoading}
        />
        <SendButton type="submit" disabled={isLoading}>
          <Icon type={IconOption.SubmitChat} size="16px" />
        </SendButton>
      </InputForm>
    </Container>
  );
};
