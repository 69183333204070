// Shown Context handles state of which cards are shown
import React, { useEffect, useState, ReactNode, useContext, useCallback } from 'react';
import type { UUID, NoteMap, Note, Source } from 'core/types';
import { isSource, isAnnote } from 'core/types';
import { scrollToNote } from 'src/helpers/scrolling';
import { CardSize } from 'src/types';
import { useBrainContext } from './BrainContext';
import { FocusContext } from './FocusContext';

type SizeMap = Record<UUID, CardSize>;
const sizeKeyIndex: Record<string, CardSize> = { S: 'SMALL', M: 'MEDIUM', L: 'LARGE' };

export type ShownIdsContextType = {
  shownIds: UUID[];
  surfaceNoteMap: NoteMap;
  show: (noteId: UUID | UUID[]) => void;
  showAafterB: (a: UUID | UUID[], b?: UUID) => void;
  showAbeforeB: (a: UUID | UUID[], b?: UUID) => void;
  hide: (noteId: UUID | UUID[], ignoreSources?: boolean) => void;
  clear: () => void;
  updateSize: (noteId: UUID, size: CardSize) => void;
  shownSizes: SizeMap;
};

const defaultShownIdsContext: ShownIdsContextType = {
  shownIds: [],
  surfaceNoteMap: {},
  show: () => {},
  shownSizes: {},
  updateSize: () => {},
  showAafterB: () => {},
  showAbeforeB: () => {},
  hide: () => {},
  clear: () => {},
};

// Unfortunately this is necessary for the comparison of two lists of ids
const areArraysEqual = (a: UUID[], b: UUID[]): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const ShownIdsContext = React.createContext<ShownIdsContextType>(defaultShownIdsContext);

type ShownIdsProviderProps = {
  children: ReactNode;
};

export const ShownIdsProvider: React.FC<ShownIdsProviderProps> = ({ children }) => {
  const [shownIds, setShownIds] = useState<UUID[]>([]);
  const [shownSizes, setShownSizes] = useState<SizeMap>({});
  const [urlSource, setUrlSource] = useState<Source | undefined | null>(undefined);
  const [surfaceIds, setSurfaceIds] = useState<UUID[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const { getNoteMapByIds, getSourceByUrl, updatedNotes } = useBrainContext();
  const { setFocus } = useContext(FocusContext);
  const [shownNoteMap, setShownNoteMap] = useState<NoteMap>({});
  const [surfaceNoteMap, setSurfaceNoteMap] = useState<NoteMap>({});
  const [suggestedNoteMap, setSuggestedNoteMap] = useState<NoteMap>({});
  const urlSourceRef = React.useRef<Source | undefined | null>(undefined);
  const suggestedNoteMapRef = React.useRef<NoteMap>(suggestedNoteMap);

  // save urlSource as a ref as it lowers dependency issues
  useEffect(() => {
    urlSourceRef.current = urlSource;
  }, [urlSource]);

  // function to change the size (small, medium, large) of a note
  const updateSize = useCallback((noteId: UUID, size: CardSize): void => {
    if (noteId === urlSourceRef.current?.id) {
      setUrlSource(null); // remove the ?url= get param if its size was updated
    }
    setShownSizes((currentShownSizes) => ({ ...currentShownSizes, [noteId]: size }));
  }, []);

  // gets notes from the database and suggestedNoteMap
  const getNotes = useCallback(
    async (ids: UUID[]): Promise<NoteMap> => {
      // notes with .isSuggested=true are not stored in the database
      const suggested = ids.reduce((acc, id) => {
        const note = suggestedNoteMap[id];
        if (note) acc[id] = note;
        return acc;
      }, {} as NoteMap);

      const notesFromDB = await getNoteMapByIds(ids);
      return { ...suggested, ...notesFromDB };
    },
    [getNoteMapByIds],
  );

  // adds the noteId (or list of noteIds) to the front of shownIds
  const show = useCallback((noteId: UUID | UUID[]): void => {
    const idsToShow = Array.isArray(noteId) ? noteId : [noteId];
    setShownIds((currentShownIds) => {
      const cleanIds = currentShownIds.filter((id) => !idsToShow.includes(id));
      return [...idsToShow, ...cleanIds];
    });
  }, []);

  const showAafterB = useCallback((a: UUID | UUID[], b?: UUID): void => {
    if (!b) {
      show(a);
      return;
    }
    const idsToAdd = Array.isArray(a) ? a : [a];
    if (idsToAdd.length === 1 && idsToAdd[0] === b) return; // same spot - don't move
    setShownIds((currentShownIds) => {
      const shownIdsWithoutA = currentShownIds.filter((id) => !idsToAdd.includes(id)); // Note B must exist
      const indexOfB = shownIdsWithoutA.indexOf(b);
      const beforeB = shownIdsWithoutA.slice(0, indexOfB + 1);
      const afterB = shownIdsWithoutA.slice(indexOfB + 1);
      return [...beforeB, ...idsToAdd, ...afterB];
    });
  }, []);

  const showAbeforeB = useCallback((a: UUID | UUID[], b?: UUID): void => {
    if (!b) {
      show(a);
      return;
    }
    const idsToAdd = Array.isArray(a) ? a : [a];
    setShownIds((currentShownIds) => {
      const shownIdsWithoutA = currentShownIds.filter((id) => !idsToAdd.includes(id)); // Note B must exist
      const indexOfB = shownIdsWithoutA.indexOf(b);
      const beforeB = shownIdsWithoutA.slice(0, indexOfB);
      const afterB = shownIdsWithoutA.slice(indexOfB);
      return [...beforeB, ...idsToAdd, ...afterB];
    });
  }, []);

  const clear = useCallback((): void => {
    setShownIds([]);
    setShownSizes({});
    setUrlSource(null);
  }, []);

  const shownNoteMapRef = React.useRef(shownNoteMap);

  useEffect(() => {
    shownNoteMapRef.current = shownNoteMap;
  }, [shownNoteMap]);

  // Needs to be a callback because it's passed as context to other components
  const hide = useCallback((noteId: UUID | UUID[], ignoreSources = false): void => {
    const idsToRemove: UUID[] = Array.isArray(noteId) ? noteId : [noteId];

    if (!ignoreSources) {
      const notesToRemove = idsToRemove.map((id) => shownNoteMapRef.current[id]).filter((n) => n);
      notesToRemove.forEach((n) => {
        if (isSource(n)) {
          // if the source to be hidden matches the reader url, clear the reader url
          if (urlSourceRef.current && n.id === urlSourceRef.current?.id) setUrlSource(null);

          // Remove shown annotes of source we're hiding
          const shownAnnotes = n.links
            .map((id) => shownNoteMapRef.current[id])
            .filter((linkedNote) => linkedNote && isAnnote(linkedNote));
          const shownAnnoteIds = shownAnnotes.map((annote) => annote.id);
          idsToRemove.push(...shownAnnoteIds);
        }
      });
    }

    setShownIds((currentShownIds) => {
      const filteredIds = currentShownIds.filter((id) => !idsToRemove.includes(id));
      return filteredIds;
    });
  }, []);

  // Store mutable values in refs
  const stateRef = React.useRef({
    hide,
    shownIds,
    surfaceIds,
  });

  // workaround to ensure these callbacks don't trigger extra updates
  useEffect(() => {
    stateRef.current = {
      hide,
      shownIds,
      surfaceIds,
    };
  }, [hide, shownIds, surfaceIds]);

  // Set the initial shownIds and sizes from the URL after the component mounts
  useEffect(() => {
    if (isInitialized) return;
    const params = new URLSearchParams(window.location.search);
    const idsAndSizes = (params.get('docs') || '').split(',').filter((id) => id !== '');

    const sizes: SizeMap = {};
    const ids: UUID[] = [];
    idsAndSizes.forEach((idAndSize) => {
      const parts = idAndSize.split(':');
      const id = parts[0];
      ids.push(id);
      if (parts.length > 1) {
        // Only add to sizes if there's actually a size specified
        const size = parts[1].toUpperCase();
        if (size in sizeKeyIndex) {
          sizes[id] = sizeKeyIndex[size] as CardSize;
        }
      }
    });

    // show a large source from the url parm if it is there
    const url = params.get('url') || null;
    if (url) {
      getSourceByUrl(url).then((source) => {
        if (!source) {
          setUrlSource(null);
          return;
        }
        setUrlSource(source);
        if (!ids.includes(source.id)) {
          show(source.id);
        }
        setShownSizes((currentShownSizes) => ({ ...currentShownSizes, [source.id]: 'LARGE' }));
      });
    }

    setShownSizes(sizes);
    setIsInitialized(true);
    show(ids);
  }, []);

  // Update the Window Location whenever shownIds, sizes, or readerUrl change
  useEffect(() => {
    if (!isInitialized) return;
    const params = new URLSearchParams(window.location.search);
    if (urlSource === null) {
      params.delete('url');
    }
    params.delete('docs');

    // put the sizes with the ids in the following format: id:S,id:L
    const idsWithSizes = shownIds
      .map((id) => {
        if (!shownSizes[id]) return id;
        return `${id}:${shownSizes[id][0]}`;
      })
      .join(',');
    if (idsWithSizes.length > 0) params.set('docs', idsWithSizes);
    let paramsString = `?${params.toString()}`;
    if (paramsString.length < 2) paramsString = ''; // remove the ? if there are no params

    window.history.replaceState({}, '', `${window.location.pathname}${paramsString}`);
  }, [shownIds, shownSizes, urlSource]);

  useEffect(() => {
    if (!updatedNotes.length) return;

    const updateSuggestedInner = (updates: Note[]): void => {
      const idsToRemove = updates.filter((n) => n.deletedAt || !n.isSuggestion).map((n) => n.id);
      const newSuggestions = updates.filter((n) => !n.deletedAt && n.isSuggestion);
      if (idsToRemove.length === 0 && newSuggestions.length === 0) return;
      setSuggestedNoteMap((prev) => {
        const newSuggestedNoteMap: NoteMap = {};
        Object.keys(prev).forEach((id) => {
          if (!idsToRemove.includes(id)) newSuggestedNoteMap[id] = prev[id];
        });
        newSuggestions.forEach((n) => {
          newSuggestedNoteMap[n.id] = n;
        });
        return newSuggestedNoteMap;
      });
    };

    const handleUpdateOfNoteMapInner = (
      updates: Note[],
      ids: UUID[],
      setFunction: React.Dispatch<React.SetStateAction<NoteMap>>,
    ): void => {
      const matchingUpdates = updates.filter((n) => ids.includes(n.id));
      if (matchingUpdates.length === 0) return;

      const deletedUpdateIds = matchingUpdates.filter((n) => n.deletedAt).map((n) => n.id);
      stateRef.current.hide(deletedUpdateIds);

      setFunction((prev: NoteMap): NoteMap => {
        const newNoteMap = { ...prev };
        matchingUpdates.forEach((n) => {
          if (n.deletedAt) {
            delete newNoteMap[n.id];
          } else {
            newNoteMap[n.id] = n;
          }
        });
        return newNoteMap;
      });
    };

    console.log('updating suggested and shown notes due to updatedNotes', updatedNotes);
    updateSuggestedInner(updatedNotes);
    handleUpdateOfNoteMapInner(updatedNotes, stateRef.current.shownIds, setShownNoteMap);
    handleUpdateOfNoteMapInner(updatedNotes, stateRef.current.surfaceIds, setSurfaceNoteMap);
  }, [updatedNotes]); // Only updatedNotes triggers a re-run

  useEffect(() => {
    console.log('ShownContext: suggestedNoteMap changed', suggestedNoteMap);
  }, [suggestedNoteMap]);

  useEffect(() => {
    console.log('ShownContext: shownNoteMap changed', shownNoteMap);
  }, [shownNoteMap]);

  useEffect(() => {
    console.log('ShownContext: surfaceNoteMap changed', surfaceNoteMap);
  }, [surfaceNoteMap]);

  // update a noteMap based on a list of ids
  const hydrateIds = useCallback(
    async (ids: UUID[], setFunction: React.Dispatch<React.SetStateAction<NoteMap>>): Promise<void> => {
      const freshNotes = await getNotes(ids);
      Object.keys(suggestedNoteMapRef.current).forEach((id) => {
        if (ids.includes(id)) {
          console.log('suggested id found in ids', id);
          freshNotes[id] = suggestedNoteMapRef.current[id];
        }
      });
      console.log('freshNotes', freshNotes);
      setFunction(freshNotes);
    },
    [getNotes],
  );

  // shownIds ==> shownNoteMap
  // keep shownNoteMap hydrated and up to date with shownIds
  useEffect(() => {
    console.log('ShownContext: shownIds changed - hydrating shownNoteMap', shownIds);
    hydrateIds(shownIds, setShownNoteMap);
  }, [shownIds]);

  // shownNoteMap ==> surfaceIds
  // populate surfaceIds -> a list of ids that the shownNotes depend on (their connections)
  // these are all the notes that are needed to show the surface and will be hydrated in surfaceNoteMap
  useEffect(() => {
    // all links and backlinks of shownNotes
    const connectedIds = Object.values(shownNoteMap).flatMap((n: Note) => [...(n.links || []), ...(n.backlinks || [])]);
    const uniqueSurfaceIds = Array.from(new Set([...stateRef.current.shownIds, ...connectedIds].sort()));
    // don't refresh if the ids are the same
    if (areArraysEqual(uniqueSurfaceIds, surfaceIds)) return;
    setSurfaceIds(uniqueSurfaceIds);
  }, [shownNoteMap]);

  // surfaceIds ==> surfaceNoteMap
  // keep surfaceNoteMap hydrated and up to date with surfaceIds
  useEffect((): (() => void) | void => {
    hydrateIds(surfaceIds, setSurfaceNoteMap);
  }, [surfaceIds]);

  // Make sure all sources of any annote in surfaceNoteMap are also in surfaceIds (and therefore the map)
  // also ensure all links from annotes are in surfaceIds
  useEffect(() => {
    // ensure sources of all annotes are in the surfaceIds
    const annotes = Object.values(surfaceNoteMap).filter((n) => isAnnote(n));
    const sourceIds = new Set(annotes.map((n) => n.createdFromId!));
    const unlistedSources = Array.from(sourceIds).filter((id) => !surfaceIds.includes(id));

    // ensure links and backlinksfrom all annotes are in the surfaceIds
    const annoteConnections = annotes.flatMap((a) => [...(a.links || []), ...(a.backlinks || [])]);
    const newSurfaceIds = Array.from(new Set([...surfaceIds, ...unlistedSources, ...annoteConnections]));
    if (surfaceIds.length === newSurfaceIds.length) return;
    setSurfaceIds(newSurfaceIds);
  }, [surfaceNoteMap]);

  // Handle when links in notes are clicked
  useEffect(() => {
    const handleLinkClick = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;

      // uncomment if you want to block external links too
      // if (!target.matches('a')) return;
      const href = target.getAttribute('href');
      if (!href) return;
      // Focus on the internal link
      if (!target.classList.contains('internal-link')) return;

      e.preventDefault();
      e.stopPropagation();
      const noteId = href;
      setFocus(noteId);
      if (!stateRef.current.shownIds.includes(noteId)) show(noteId);
      // TODO probably a better listener for this than a timeout
      setTimeout(() => scrollToNote(noteId), 100);
    };
    document.addEventListener('click', handleLinkClick);
    return () => document.removeEventListener('click', handleLinkClick);
  }, [show]);

  // Listener for sources being fetched from the extension
  // Extension can call showAbeforeB, show, and showAafterB
  // TODO - show calls here may need to use refs or be stale
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.source !== window) return;
      if (event.data.action === 'showAbeforeB') {
        const response = event.data;
        showAbeforeB(response.a, response.b);
      }
      if (event.data.messageType === 'EXT_REQUEST' && event.data.message) {
        const response = event.data.message;
        const { action } = response;
        if (action === 'show') {
          if (response.ids.length === 0) return;
          show(response.ids);

          setTimeout(() => {
            show(response.ids);
            setFocus(response.ids[0]);
          }, 100);
        }
      }
    });
  }, []);

  useEffect(() => {
    suggestedNoteMapRef.current = suggestedNoteMap;
  }, [suggestedNoteMap]);

  return (
    <ShownIdsContext.Provider
      value={{
        shownIds,
        show,
        showAafterB,
        showAbeforeB,
        hide,
        clear,
        updateSize,
        shownSizes,
        surfaceNoteMap,
      }}
    >
      {children}
    </ShownIdsContext.Provider>
  );
};
