import React, { Fragment, useEffect, useState, useContext, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { Note, TypeOfNote, TYPES_OF_NOTES, NOTE_TAB_LABELS, SearchResults, SearchNote } from 'core/types';
import { debounce } from 'lodash';
import { text, colors } from 'core/styles';
import { useDragging } from 'src/context/DraggingContext';
import { SearchContext } from 'src/context/SearchContext';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { scrollToNote } from 'src/helpers/scrolling';
import { FocusContext } from 'src/context/FocusContext';
import SearchBar from './SearchBar';

import { CardList, ListCard } from './Cards/ListCard';
import PreviewCard from './Cards/PreviewCard';

const LibraryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  gap: 1rem;
  width: 36rem;
  padding: 2rem;
  background: ${colors.bg.library};
  z-index: 20;
  box-shadow: ${colors.shadow.toolbar};
  border-radius: 0.75rem 0 0.75rem 0.75rem;
`;

const HideDropZone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.bg.dropzone};
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  z-index: auto;

  &:hover {
    z-index: 20;
  }
`;

const OverlapWrapper = styled.div`
  height: 8px; // This creates the overlap effect
  /* margin-top: -4px; // Compensate for the height to maintain overall spacing */
  position: relative;
`;

const CardGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 4.25rem;
  width: 38rem;
`;

const TypeRow = styled.div`
  display: flex;
  align-items: center;
`;

const EmptyResponse = styled.div`
  color: ${colors.text.placeholder};
  font-size: ${text.size.primary};
  font-weight: ${text.weight.medium};
`;

const TypeButton = styled.div<{ isSelected: boolean }>`
  font-weight: ${text.weight.medium}; //500
  font-size: 1.75rem;
  line-height: 2rem;
  padding-bottom: 0.5rem;
  text-align: center;
  border-bottom: 2px solid transparent;
  color: ${colors.text.button};
  ${(props) => (props.isSelected ? `border-bottom: 2px solid ${colors.text.primary};` : '')}
  margin: 1rem 1.5rem;
  text-transform: capitalize;

  cursor: pointer;

  &:hover {
    border-bottom: 2px solid ${colors.icon.primary};
  }
`;

const CardGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 35rem;
  padding-left: 2px;
`;

const CardGroupTitle = styled.span`
  color: ${colors.text.button};
  font-family: ${text.family.primary};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: ${text.weight.medium}; //500
  line-height: 1.75rem;
  text-transform: capitalize;
  padding-bottom: 1rem;
`;

const DivideLine = styled.div`
  opacity: 0.5;
  background: linear-gradient(180deg, rgba(173, 173, 173, 0.6) 0%, rgba(217, 217, 217, 0) 98.96%);
  height: 2px;
  width: 100%;
`;

type DateGroup = {
  label: string;
  minDate: string;
  notes: SearchResults;
};

// Groups notes into relative date buckets
// Assumes that notes are ordered by createdat in descending order
const groupByDate = (sortedNotes: Note[]): DateGroup[] => {
  const now = new Date();
  const dateGroups: DateGroup[] = [
    {
      label: 'just now',
      minDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours()).toISOString(),
      notes: [],
    },
    { label: 'today', minDate: new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString(), notes: [] },
    {
      label: 'yesterday',
      minDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toISOString(),
      notes: [],
    },
    {
      label: 'previous 7 days',
      minDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).toISOString(),
      notes: [],
    },
    {
      label: 'previous 30 days',
      minDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30).toISOString(),
      notes: [],
    },
    {
      label: 'previous 90 days',
      minDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 90).toISOString(),
      notes: [],
    },
    { label: 'this year', minDate: new Date(now.getFullYear(), 0, 1).toISOString(), notes: [] },
    { label: 'alltime', minDate: new Date('1/1/2022').toISOString(), notes: [] },
  ];
  let currentGroupNum = 0;
  const nullGroupNum = 0;

  sortedNotes.forEach((note) => {
    if (!note) return;
    if (!note.syncedAt) {
      dateGroups[nullGroupNum].notes.push(note);
      return;
    }
    for (let i = currentGroupNum; i < dateGroups.length; i += 1) {
      const currentGroup = dateGroups[i];
      if (note.syncedAt > currentGroup.minDate) {
        dateGroups[currentGroupNum].notes.push(note);
        break;
      } else {
        currentGroupNum += 1;
      }
    }
  });
  return dateGroups.filter((group) => group.notes.length > 0);
};

const Library: React.FC<{ keepShownNotes?: boolean }> = ({ keepShownNotes = true }) => {
  const { searchTerm, setSearchTerm, typeFilter, setTypeFilter } = useContext(SearchContext);
  const [groupedSearchResults, setGroupedSearchResults] = useState<DateGroup[]>([]);
  const { searchNotes, updatedNotes } = useBrainContext();
  const [selectedResults, setSelectedResults] = useState<SearchResults>([]);
  const { show, hide, shownIds } = useContext(ShownIdsContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hoveredCard, setHoveredCard] = useState<Note | SearchNote | null>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const { isUserDragging, isOverLibrary } = useDragging();
  const { setFocus } = useContext(FocusContext);

  const [searchResults, setSearchResults] = useState<SearchResults>([]);

  // New function to fetch and set search results
  const fetchSearchResults = useCallback(async () => {
    const results = await searchNotes(searchTerm, typeFilter ? [typeFilter] : undefined, 100);
    setSearchResults(results);
  }, [searchTerm, typeFilter, searchNotes]);

  // Use useEffect to call fetchSearchResults when initiated
  useEffect(() => {
    fetchSearchResults();
  }, [fetchSearchResults]);

  const [{ isOver }, libRef] = useDrop<Note | SearchNote, void, { isOver: boolean }>({
    accept: ['CARD'],
    drop: (item: Note | SearchNote, monitor) => {
      if (!monitor.didDrop()) {
        hide(item.id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
  });

  // update the search results with any updates to the notes
  useEffect(() => {
    if (!updatedNotes || updatedNotes.length === 0) return;

    const updatedNoteMap = new Map(updatedNotes.map((note) => [note.id, note]));
    const updatedIds = Array.from(updatedNoteMap.keys());

    // if a note that's not in the search results now has the search term in it, refetch
    const resultIds = searchResults.map((sr) => sr.id);
    const updatesThatAreNotInResults = updatedNotes.filter((note) => !resultIds.includes(note.id));
    const shouldRefetch =
      updatesThatAreNotInResults.some((note) => {
        const content = `${note.title.toLowerCase()} ${note.value.toLowerCase()}`;
        return content.includes(searchTerm.toLowerCase());
      }) || updatedNotes.some((note) => note.deletedAt);

    if (shouldRefetch) {
      setTimeout(() => {
        fetchSearchResults();
      }, 200);
      return;
    }

    // Simply update search results with the new notes
    setSearchResults((prev) => {
      const newResults = [...prev];
      updatedIds.forEach((id) => {
        const index = newResults.findIndex((sr) => sr.id === id);
        const updatedNote = updatedNoteMap.get(id);
        if (index !== -1 && updatedNote) {
          newResults[index] = {
            title: updatedNote.title,
            value: updatedNote.value,
            id: updatedNote.id,
            type: updatedNote.type,
            url: updatedNote.url,
          };
        }
      });
      return newResults;
    });
  }, [updatedNotes]);

  // Filter and group the memoized search results
  useEffect(() => {
    const processSearchResults = async (): Promise<void> => {
      let results = searchResults;

      // seperate the selected results
      setSelectedResults(results.filter((note) => shownIds.includes(note.id)));
      results = results.filter((note) => !shownIds.includes(note.id));

      if (results.length === 0) {
        setGroupedSearchResults([]);
      } else if (searchTerm !== '') {
        // Latest notes
        setGroupedSearchResults([{ label: 'search results', minDate: '', notes: results }]);
      } else {
        const localGroupedSearchResults = groupByDate(results as Note[]);
        setGroupedSearchResults(localGroupedSearchResults);
      }
    };

    processSearchResults();
  }, [searchResults, shownIds, keepShownNotes, searchTerm]);

  // Update the search term in context with debounce
  const onSearchValueChange = debounce((value: string): void => {
    setSearchTerm(value);
  }, 300); // Adjust the debounce delay (in milliseconds) to match the desired typing speed catch

  const handleSelectType = (type?: TypeOfNote) => () => {
    setTypeFilter(type);
  };

  const selectCard = (note: Note | SearchNote): void => {
    show(note.id);
    setTimeout(() => {
      setFocus(note.id);
      scrollToNote(note.id);
    }, 50);
  };

  return (
    <LibraryWrapper ref={libRef}>
      {(isOver || isOverLibrary) && <HideDropZone>Hide card</HideDropZone>}
      <SearchBar onSearch={onSearchValueChange} />
      <TypeRow>
        <TypeButton key="all" isSelected={!typeFilter} onClick={handleSelectType()}>
          All
        </TypeButton>
        {TYPES_OF_NOTES.map((noteType: TypeOfNote) => {
          return (
            <TypeButton key={noteType} isSelected={noteType === typeFilter} onClick={handleSelectType(noteType)}>
              {NOTE_TAB_LABELS[noteType]}
            </TypeButton>
          );
        })}
      </TypeRow>
      <CardGrid ref={containerRef}>
        {searchTerm.length > 0 && searchNotes.length === 0 && (
          <div>
            <p>No results found</p>
          </div>
        )}
        {hoveredCard && previewRef && !isUserDragging && (
          <PreviewCard note={hoveredCard as Note} isTriggerHovered={true} triggerRef={previewRef} />
        )}
        {groupedSearchResults.length === 0 && <EmptyResponse>No notes found for "{searchTerm}"</EmptyResponse>}
        {selectedResults.length > 0 && (
          <CardGroup key="selected">
            <CardGroupTitle>Selected ({selectedResults.length})</CardGroupTitle>
            <CardList style={{ paddingBottom: '3rem' }}>
              {selectedResults.map((note) => (
                <Fragment key={note.id}>
                  <OverlapWrapper>
                    <CardWrapper
                      key={`wrapper-${note.id}`}
                      onClick={() => selectCard(note)}
                      onMouseEnter={() => setHoveredCard(note)}
                      onMouseLeave={() => setHoveredCard(null)}
                      ref={note.id === hoveredCard?.id ? previewRef : null}
                    >
                      <ListCard note={note as Note} />
                    </CardWrapper>
                  </OverlapWrapper>
                </Fragment>
              ))}
            </CardList>
          </CardGroup>
        )}
        {groupedSearchResults.length > 0 &&
          groupedSearchResults.map((group) => (
            <CardGroup key={group.label}>
              <CardGroupTitle>{group.label}</CardGroupTitle>
              <CardList>
                {group.notes.map((note, index) => (
                  <Fragment key={note.id}>
                    {' '}
                    <CardWrapper
                      key={`wrapper-${note.id}`}
                      onClick={() => selectCard(note)}
                      onMouseEnter={() => setHoveredCard(note)}
                      onMouseLeave={() => setHoveredCard(null)}
                      ref={note.id === hoveredCard?.id ? previewRef : null}
                    >
                      <ListCard
                        key={`sc-${note.id}`}
                        note={note as Note}
                        disabled={keepShownNotes && shownIds.includes(note.id)}
                      />
                      {index < group.notes.length - 1 && <DivideLine />}
                    </CardWrapper>
                  </Fragment>
                ))}
              </CardList>
            </CardGroup>
          ))}
      </CardGrid>
    </LibraryWrapper>
  );
};

export default Library;
