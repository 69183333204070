import styled from 'styled-components';
import { Note, Source, Takeaway } from 'core/types';
import { createRef, useState, useContext, useMemo } from 'react';
import { colors } from 'core/styles';
import { useDragging } from 'src/context/DraggingContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { ReactComponent as ExpandArrow } from 'src/components/Icon/icons/show_backlinks.svg';
import { ListTakeawayCard } from './byType/takeaway/ListTakeaway';
import { ListSourceCard } from './byType/source/ListSource';
import PreviewCard from './PreviewCard';

const DEFAULT_OFFSET_IN_REM = 0.7;
const CARD_OFFSET_IN_STACK_IN_REM = 0.7;
// const HOVER_OFFSET_HIDDEN_CARDS_IN_REM = 5.5;

type HiddenCardKind = 'links' | 'backlinks';

const AllButton = styled.div<{ kind: HiddenCardKind }>`
  position: absolute;
  visibility: hidden;
  ${(props) => (props.kind === 'backlinks' ? 'top: -30px; right: -30px;' : 'bottom: -30px; left: -30px;')}
`;

const HiddenWrapper = styled.div<{ kind: HiddenCardKind }>`
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 300px; // TODO - should be smarter

  &::before {
    cursor: pointer;
    content: '';
    position: absolute;
    width: 200px;
    height: 60px;
    ${(props) => (props.kind === 'backlinks' ? 'top: -30px; right: -30px;' : 'bottom: -30px; left: -30px;')}
    z-index: 1;
  }

  &:hover > ${AllButton} {
    visibility: visible;
    z-index: 2;
  }
`;

export const HiddenCardWrapper = styled.div<{ isOnBottom: boolean; offset: number }>`
  width: 100%;
  height: 60%;
  z-index: 10;
  display: flex;
  background: ${colors.bg.primary};
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  bottom: ${(props) =>
    props.isOnBottom ? `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem` : 'unset'};
  top: ${(props) =>
    props.isOnBottom ? 'unset' : `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem`};
  left: ${(props) =>
    props.isOnBottom ? `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem` : 'unset'};
  right: ${(props) =>
    props.isOnBottom ? 'unset' : `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem`};

  transition: top 0.3s ease-in-out, bottom 0.3s ease-in-out;

  box-shadow: 0px 1px 2px 0px rgba(15, 15, 15, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);

  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

type HiddenCardProps = {
  isOnBottom?: boolean;
  offset?: number;
  note: Note;
  className?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | Promise<void>;
};

const HiddenCard: React.FC<HiddenCardProps> = ({ note, isOnBottom = false, offset = 0, onClick, className }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isUserDragging } = useDragging();
  const ref = createRef<HTMLDivElement>();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onClick(e);
  };

  // Hidden cards are not useful when a user is dragging
  if (isUserDragging) return null;

  return (
    <>
      <HiddenCardWrapper
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isOnBottom={isOnBottom}
        offset={offset}
        className={className}
        ref={ref}
      >
        {note.type === 'takeaway' && <ListTakeawayCard takeaway={note as Takeaway} />}
        {note.type === 'source' && <ListSourceCard source={note as Source} />}
      </HiddenCardWrapper>
      {isHovered && (
        <PreviewCard
          note={note}
          isTriggerHovered={isHovered}
          triggerRef={ref}
          cornerPosition={isOnBottom ? 'bottom-left' : 'top-right'}
        />
      )}
    </>
  );
};

type HiddenCardsProps = {
  kind: HiddenCardKind;
  hiddenNotes: Note[];
  note: Note;
};

const HiddenCards: React.FC<HiddenCardsProps> = ({ kind, hiddenNotes, note }) => {
  const { showAafterB, showAbeforeB, hide } = useContext(ShownIdsContext);

  const showFunc = useMemo(
    () => (kind === 'backlinks' ? showAafterB : showAbeforeB),
    [kind, showAafterB, showAbeforeB],
  );
  const classPrefix = useMemo(() => (kind === 'backlinks' ? 'hidden-top-card' : 'hidden-bottom-card'), [kind]);
  const showButton: boolean = useMemo(() => note[kind]?.length > 0 || false, [note[kind]]);
  return (
    <HiddenWrapper kind={kind}>
      {showButton && (
        <AllButton
          kind={kind}
          onClick={(e) => {
            e.stopPropagation();
            console.log('clicked all');
            if (hiddenNotes.length) {
              showFunc(
                hiddenNotes.map((n) => n.id),
                note.id,
              );
            } else {
              hide(note[kind]);
            }
          }}
        >
          <ExpandArrow
            style={{
              transform: `rotate(${hiddenNotes.length === 0 ? '180deg' : '0deg'})`,
            }}
          />
        </AllButton>
      )}
      {hiddenNotes.map((hiddenNote, i) => (
        <HiddenCard
          key={`h${kind.slice(0, 1)}-${hiddenNote.id}`}
          note={hiddenNote}
          onClick={(e) => {
            e.stopPropagation();
            showFunc(hiddenNote.id, note.id);
          }}
          isOnBottom={kind === 'links'}
          offset={hiddenNotes.length - (i + 1)}
          className={`${classPrefix}-${hiddenNotes.length - i}`}
        />
      ))}
    </HiddenWrapper>
  );
};

export default HiddenCards;
