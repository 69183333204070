// a button that optionally shows its children as a menu when clicked

import React, { useRef, useEffect } from 'react';
import { SVGIcon } from 'src/components/Icon/Icon';
import styled from 'styled-components';
import { colors } from 'core/styles';
import { MenuProps } from './MenuComponents';

const ICON_SIZE = '16px';

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const IconButton = styled.button<{ isSelected?: boolean }>`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${(props) => (props.isSelected ? colors.button.selected : 'transparent')};
  &:hover {
    background-color: ${colors.button.hover};
  }
`;

interface CardMenuButtonProps {
  iconSVG: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

export const CardMenuButton: React.FC<CardMenuButtonProps> = ({ iconSVG, children, isOpen, onToggle }) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    if (children) {
      onToggle();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (buttonRef.current && !buttonRef.current.contains(event.target as Node) && isOpen) {
        onToggle();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onToggle]);

  return (
    <ButtonWrapper ref={buttonRef}>
      <IconButton onClick={handleClick} isSelected={isOpen}>
        <SVGIcon svgComponent={iconSVG} size={ICON_SIZE} />
      </IconButton>
      {isOpen &&
        React.isValidElement(children) &&
        React.cloneElement(children as React.ReactElement<MenuProps>, {
          closeMenu: () => onToggle(),
        })}
    </ButtonWrapper>
  );
};

interface CardButtonProps {
  iconSVG: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
  className?: string;
}

// Look sthe same as card Menu Button but without the menu
export const CardButton = React.forwardRef<HTMLDivElement, CardButtonProps>(({ iconSVG, onClick, className }, ref) => {
  return (
    <ButtonWrapper ref={ref} className={className}>
      <IconButton onClick={onClick}>
        <SVGIcon svgComponent={iconSVG} size={ICON_SIZE} />
      </IconButton>
    </ButtonWrapper>
  );
});

export default CardMenuButton;
