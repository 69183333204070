/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-globals */
import { SupabaseClient } from '@supabase/supabase-js';
import { Database, Note, TypeOfNote, UUID, Profile } from 'core/types';
import { IDatabase } from './types';

// hard coded in as env variables are different in core vs application
// TODO - this should be a config variable
const allowedUrls = [
  'https://vyffaascqnqmuqgagztb.supabase.co/',
  'https://vyffaascqnqmuqgagztb.functions.supabase.co/',
  'http://localhost:54321/',
  'https://annote.com/',
  'http://api.annote.com/',
];

export class SupabaseDatabase implements IDatabase {
  db: SupabaseClient<Database>;

  userId?: UUID;

  private isUrlBlocked: (url: string) => boolean = () => false;

  constructor(db: SupabaseClient<Database>) {
    this.db = db;
    this.searchNotes = this.searchNotes.bind(this);
    this.getNotes = this.getNotes.bind(this);
    this.getNotesByUrl = this.getNotesByUrl.bind(this);
    this.getNoteById = this.getNoteById.bind(this);
    this.getNoteByTitle = this.getNoteByTitle.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.deleteNotes = this.deleteNotes.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  // cache the userId and isURLBlocked function
  async initFromSelf(): Promise<void> {
    console.log('localStorage keys:', Object.keys(localStorage));
    console.log('sessionStorage keys:', Object.keys(sessionStorage));

    // Get the session and user
    const {
      data: { session },
      error,
    } = await this.db.auth.getSession();
    if (error) throw error;
    const user = session?.user;
    console.log('Supabase initFromSelf', session, user?.id);

    if (!user?.id) return;
    console.log('Supabase initFromSelf - got user id', user.id);
    this.userId = user.id;

    // TODO - this is an unecessary fetch - we've already fetched this other ways
    // but for now it nicely keeps code seperate
    const { data: profile, error: err } = await this.db.from('Profiles').select().eq('userId', user.id).single();
    if (err) throw err;
    if (!profile) return;

    console.log('user and profile from supabase init from self', user, profile);
    const isUrlBlocked = (url: string): boolean => {
      const { host } = new URL(url);
      return profile?.blockedHosts.includes(host);
    };

    this.isUrlBlocked = isUrlBlocked;
  }

  async getNotes(ids?: UUID[]): Promise<Note[]> {
    if (!this.userId) {
      throw new Error('User not authenticated - getNotes');
    }
    let query = this.db.from('Notes').select().eq('userId', this.userId).is('deletedAt', null);
    if (ids && ids.length > 0) query = query.in('id', ids);

    const { data: notes, error } = await query;
    if (error) throw error;
    return notes as Note[];
  }

  async getNotesByUrl(url: string): Promise<{ notes: Note[]; isBlocked: boolean }> {
    if (!this.userId) {
      throw new Error('User not authenticated - getNotesByUrl');
    }
    if (this.isUrlBlocked && this.isUrlBlocked(url)) {
      return { notes: [], isBlocked: true };
    }
    const { data: notes, error } = await this.db
      .from('Notes')
      .select()
      .eq('url', url)
      .eq('userId', this.userId) // extra check - even though row level security should be sufficient
      .is('deletedAt', null);
    if (error) throw error;
    return { notes: notes as Note[], isBlocked: false };
  }

  async getNoteById(id: UUID): Promise<Note | null> {
    if (!this.userId) {
      throw new Error('User not authenticated - getNoteById');
    }
    const { data: notes, error } = await this.db
      .from('Notes')
      .select()
      .eq('id', id)
      .eq('userId', this.userId) // extra check - even though row level security should be sufficient
      .is('deletedAt', null);
    if (error) throw error;
    if (notes.length > 0) {
      return notes[0] as Note;
    }
    return null;
  }

  async getNoteByTitle(title: string): Promise<Note | null> {
    if (!this.userId) throw new Error('User not authenticated - getNoteByTitle');

    const { data: notes, error } = await this.db
      .from('Notes')
      .select()
      .eq('title', title)
      .eq('userId', this.userId) // extra check - even though row level security should be sufficient
      .is('deletedAt', null);
    if (error) throw error;
    if (notes.length > 0) {
      return notes[0] as Note;
    }
    return null;
  }

  async _latestNotes(types: TypeOfNote[] = ['takeaway', 'source'], limit: number = 100): Promise<Note[]> {
    if (!this.userId) throw new Error('User not authenticated - _latestNotes');

    let queryBuilder = this.db
      .from('Notes')
      .select()
      .eq('userId', this.userId)
      .is('deletedAt', null)
      .order('syncedAt', { ascending: false })
      .limit(limit);
    if (types && types.length > 0) {
      queryBuilder = queryBuilder.in('type', types);
    }
    const { data: notes, error } = await queryBuilder;
    if (error) throw error;
    return notes as Note[];
  }

  async searchNotes(query: string, types?: TypeOfNote[], limit: number = 10): Promise<Note[]> {
    if (!this.userId) throw new Error('User not authenticated - searchNotes');

    if (query.length === 0) return this._latestNotes(types, limit);

    const starttime = performance.now();
    let queryBuilder = this.db
      .from('Notes')
      .select()
      .eq('userId', this.userId)
      .is('deletedAt', null)
      .textSearch('title, value', query, { type: 'websearch' });

    if (types && types.length > 0) {
      queryBuilder = queryBuilder.in('type', types);
    }
    queryBuilder = queryBuilder.limit(limit);
    const { data: notes, error } = await queryBuilder;

    if (error) throw error;
    const endtime = performance.now();
    console.log(
      `Supabase searchNotes for ${query} took ${endtime - starttime} milliseconds and returned ${notes.length} notes`,
    );
    return notes as Note[];
  }

  // WRITE METHODS

  async updateNotes(notes: Note[]): Promise<void> {
    if (!this.userId) throw new Error('User not authenticated - updateNotes');
    // ensure authed userId
    const authEnsuredNotes = notes
      .filter((n) => !n.isSuggestion) // ignore suggestions
      .map((change) => {
        return { ...change, userId: this.userId };
      });
    console.log('Supabase update notes', authEnsuredNotes.length, authEnsuredNotes);
    const { error } = await this.db.from('Notes').upsert(authEnsuredNotes);
    if (error) throw error;
  }

  async deleteNotes(notes: Note[]): Promise<void> {
    // Logical delete Note by setting the deletedAt field
    console.log('supabase deleting notes', notes.length, notes);
    const now = new Date().toISOString();
    const notesToDelete = notes
      .filter((n) => !n.isSuggestion) // ignore suggestions
      .map((note) => ({ ...note, userId: this.userId, deletedAt: note.deletedAt || now }));

    // Remove all relevant denormalized Links, Backlinks and createdFromIds
    const deletedIds = notesToDelete.map((dn) => dn.id);
    const idsToUpdate: UUID[] = [];

    notesToDelete.forEach((dn) => {
      idsToUpdate.push(...dn.links);
      idsToUpdate.push(...dn.backlinks);
      if (dn.createdFromId) idsToUpdate.push(dn.createdFromId);
    });
    const allIdsToUpdate: UUID[] = Array.from(new Set(idsToUpdate)).filter((id) => !deletedIds.includes(id));

    const notesToUpdate = await this.getNotes(allIdsToUpdate);
    const updatedNotes = notesToUpdate.map((n) => {
      console.log('supabase deleting note', n.id, n.links, n.backlinks, n.createdFromId);
      const links = n.links?.filter((id) => !deletedIds.includes(id)) || [];
      const backlinks = n.backlinks?.filter((id) => !deletedIds.includes(id)) || [];
      const createdFromId = n.createdFromId && !deletedIds.includes(n.id) ? n.createdFromId : undefined;
      return { ...n, links, backlinks, createdFromId };
    });
    // Remove all inks to the deleted note before marking it deleted
    this.updateNotes(updatedNotes);

    const { error } = await this.db.from('Notes').upsert(notesToDelete);
    if (error) throw error;
  }

  async getProfile(): Promise<Profile | null> {
    if (!this.userId) return null;
    const { data: profile, error } = await this.db.from('Profiles').select().eq('userId', this.userId).single();
    if (error) throw error;
    return profile as Profile;
  }

  async updateProfile(profile: Profile): Promise<void> {
    if (!this.userId) throw new Error('User not authenticated - updateProfile');
    const { error } = await this.db.from('Profiles').upsert({ ...profile, userId: this.userId });
    if (error) throw error;
  }

  // custom fetch that uses the supabase auth token - useful for edge functions
  async fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const url = input instanceof Request ? input.url : input.toString();

    if (!allowedUrls.some((allowedUrl) => url.startsWith(allowedUrl))) {
      throw new Error(
        `SupabaseDB fetch - Invalid URL: Only requests starting with ${allowedUrls.join(', ')} are allowed`,
      );
    }

    const {
      data: { session },
      error,
    } = await this.db.auth.getSession();
    if (error) throw error;
    if (!session?.access_token) {
      throw new Error('SupabaseDB fetch - No valid session token found');
    }
    const headers = new Headers(init?.headers);
    headers.set('Authorization', `Bearer ${session.access_token}`);
    console.log('SupabaseDB fetch - headers auth', headers.get('Authorization'));
    return fetch(url, {
      ...init,
      headers,
    });
  }
}

export const isSupabaseDatabase = (db: IDatabase): db is SupabaseDatabase => {
  return db && 'db' in db && db.db instanceof SupabaseClient;
};
