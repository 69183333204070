import { z } from 'zod';
import { DynamicStructuredTool } from '@langchain/core/tools';
import { supabaseClient } from 'src/db/supabase';

// TODO - should get supabaeClient from annoteDB?

const getRelevantDocumentSectionsSchema = z.object({
  query: z.string().describe('The search query to match document sections for RAG.'),
  threshold: z.number().optional().default(0.5).describe('The threshold for matching.'),
  limit: z.number().optional().default(10).describe('Maximum number of document sections to return.'),
});

const getRelevantDocumentSectionsTool = new DynamicStructuredTool({
  name: 'get_relevant_document_sections',
  description: 'Fetch relevant document sections from the RAG database based on a search query.',
  schema: getRelevantDocumentSectionsSchema,
  func: async ({ query, threshold, limit }): Promise<string> => {
    const baseUrl: string = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;
    const endpoint: string = `${baseUrl}/getRelevantDocumentSections`;
    const session = await supabaseClient.auth.getSession();
    const userAuthToken = session.data.session?.access_token;
    console.log('User Auth Token:', userAuthToken);
    const response: Response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userAuthToken}`,
      },
      body: JSON.stringify({ query, threshold, limit }),
    });

    if (!response.ok) {
      const errorJson = await response.json();
      throw new Error(`Failed to fetch relevant document sections: ${errorJson?.error || response.statusText}`);
    }

    const { data } = await response.json();
    return JSON.stringify(data);
  },
});

export { getRelevantDocumentSectionsTool, getRelevantDocumentSectionsSchema };
