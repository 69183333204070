/*
This context is used to initialize and provide the database as annoteDB.

The database could come from the extension or from supabase.
*/

import { createContext, useContext, useEffect, useState } from 'react';
import { IDatabase } from 'core/src/dbs/types';
import { AppMessageDatabase } from 'core/dbs/AppMessageDb';
import { SupabaseDatabase } from 'core/dbs/supabaseDb';

type DataContextType = {
  annoteDB: IDatabase | null;
  isUsingExtension: boolean;
  isInitialized: boolean;
};

const DataContext = createContext<DataContextType | null>(null);

// Provided before ProfileProvider.
export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [annoteDB, setAnnoteDB] = useState<IDatabase | null>(null);
  const [isUsingExtension, setUsingExtension] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const setSupabaseAsAnnoteDB = async (): Promise<void> => {
    console.log('DataProvider - setSupabaseAsAnnoteDB.  already initialized?', isInitialized);
    if (isInitialized) return;
    // Dynamically import supabaseClient only when needed - it isn't light.
    const { supabaseClient } = await import('src/db/supabase');

    if (process.env.NODE_ENV === 'development') {
      window.supabase = supabaseClient; // for testing in dev
    }

    console.log('DataProvider - setSupabaseAsAnnoteDB.  initializing supabaseDB', supabaseClient);
    const supabaseDB = new SupabaseDatabase(supabaseClient);
    console.log('DataProvider - setSupabaseAsAnnoteDB.  supabaseDB', supabaseDB);
    await supabaseDB.initFromSelf();

    console.log('DataProvider - setting supabaseDB as annoteDB');
    setAnnoteDB(supabaseDB);
    setUsingExtension(false);
    setIsInitialized(true);
  };

  useEffect(() => {
    if (isInitialized) return;

    const checkForExtension = async (): Promise<void> => {
      const appDB = new AppMessageDatabase();

      const waitForExtension = (): Promise<void> => {
        return new Promise((resolve) => {
          const listener = (event: MessageEvent): void => {
            if (event.source !== window) return;
            if (event.data.messageType === 'APP_LISTENER_READY') {
              console.log('DataProvider: Extension is ready');
              window.removeEventListener('message', listener);
              resolve();
            }
          };
          window.addEventListener('message', listener);
        });
      };

      try {
        await Promise.race([
          waitForExtension(),
          new Promise((_, reject) => {
            setTimeout(() => reject(new Error('Extension timeout')), 2000);
          }),
        ]);

        const profile = await appDB.getProfile();
        console.log('DataProvider - got profile', profile);

        console.log('DataProvider - setting extension appDB as annoteDB');
        setAnnoteDB(appDB);
        setUsingExtension(true);
        setIsInitialized(true);
      } catch (error) {
        console.log('Extension not found or timed out, using supabase');
        setSupabaseAsAnnoteDB();
      }
    };

    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      checkForExtension();
    } else {
      setSupabaseAsAnnoteDB();
    }
  }, [isInitialized]);

  if (process.env.NODE_ENV === 'development') {
    useEffect(() => {
      window.annoteDB = annoteDB;
    }, [annoteDB]);
  }

  return <DataContext.Provider value={{ annoteDB, isUsingExtension, isInitialized }}>{children}</DataContext.Provider>;
};

export const useDataContext = (): DataContextType => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};
