import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { GOOGLE_CLIENT_ID } from 'src/config/general';
import { useProfileContext } from 'src/context/ProfileContext';
import { useDataContext } from 'src/context/DataContext';
import { isSupabaseDatabase } from 'core/dbs/supabaseDb';
import type { SupabaseClient, User } from '@supabase/supabase-js';
import { Database } from 'core/types';

const AuthWrapper = styled.div`
  padding-top: 10rem;
  display: flex;
  justify-content: center;
`;

interface CredentialResponse {
  credential: string;
}

const transferNotesIfDeveloper = async (user: User, supabaseClient: SupabaseClient<Database>): Promise<void> => {
  if (process.env.NODE_ENV !== 'development' || user.email !== 'davefowler@gmail.com') return;

  try {
    console.log('DEV: transferring notes to user', user.id);
    await supabaseClient.rpc('transfer_notes_to_user', {
      source_user_id: 'a9ec909f-855d-42ac-a124-d098824a06c4', // ID of the seed.sql user data
      target_user_id: user.id,
    });
    console.log('DEV: notes transferred to user', user.id);

    // Also add is_Staff to the user profile
    await supabaseClient
      .from('Profiles')
      .update({
        is_staff: true,
      })
      .eq('id', user.id);
    console.log('DEV: is_staff added to user', user.id);
  } catch (err) {
    console.error('DEV: Transfer error:', err);
  }
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { profile, fetchProfile } = useProfileContext();
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
  const { annoteDB } = useDataContext();

  console.log('Login!  Google client id', GOOGLE_CLIENT_ID);
  // Callback for when the user signs in with Google
  const handleCredentialResponse = useCallback(
    async (response: CredentialResponse): Promise<void> => {
      if (!annoteDB || !isSupabaseDatabase(annoteDB)) {
        console.log('annoteDB', annoteDB);
        console.error('Database not initialized or not Supabase');
        return;
      }
      const idToken = response.credential;

      try {
        console.log('Attempting to sign in with Google token...');
        const { data, error } = await annoteDB.db.auth.signInWithIdToken({
          provider: 'google',
          token: idToken,
        });

        if (error) {
          console.error('Error during Supabase sign-in:', error);
          // You might want to show this error to the user
          return;
        }

        if (data?.user) {
          try {
            await annoteDB.initFromSelf();
            const session = await annoteDB.db.auth.getSession();
            console.log('Session after sign-in:', session);

            const prof = await fetchProfile();
            console.log('Profile fetched:', prof);

            if (process.env.NODE_ENV === 'development') {
              await transferNotesIfDeveloper(data.user, annoteDB.db);
            }
          } catch (err) {
            console.error('Error during post-authentication setup:', err);
          }
        }
      } catch (err) {
        console.error('Fatal error during authentication:', err);
      }
    },
    [annoteDB, fetchProfile],
  );

  useEffect(() => {
    const loadGoogleScript = (): void => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => setIsGoogleScriptLoaded(true);
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      setIsGoogleScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (profile) {
      const redirectTo = localStorage.getItem('redirectTo');
      console.log('session is set - going to redirect soon to ', redirectTo);
      localStorage.removeItem('redirectTo');
      navigate(redirectTo || '/');
      return;
    }
    // Not yet logged in - Setup Google Sign-In
    if (isGoogleScriptLoaded && window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        // use_fedcm_for_prompt: true,
      });

      console.log('rendering google signin button');
      // Render the Google Sign-In button
      const button = document.getElementById('google-signin-button');
      if (button) {
        window.google.accounts.id.renderButton(button, {
          theme: 'outline',
          size: 'large',
          // type: 'standard',
        });
      } else {
        console.error('Google Sign-In button element not found');
      }
    }
  }, [profile, isGoogleScriptLoaded, handleCredentialResponse, navigate]);

  return (
    <AuthWrapper>
      <div id="google-signin-button"></div>
    </AuthWrapper>
  );
};

export default Login;
