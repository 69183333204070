import { IDatabase, DatabaseMessage, JSONResponse } from 'core/dbs/types';
import { Note, UUID, Profile, TypeOfNote } from 'core/types';

export class AppMessageDatabase implements IDatabase {
  private messageId = 0;

  constructor() {
    console.log('AppMessageDb: Initializing');
    this.sendMessage = this.sendMessage.bind(this);
    this.searchNotes = this.searchNotes.bind(this);
    this.getNotes = this.getNotes.bind(this);
    this.getNotesByUrl = this.getNotesByUrl.bind(this);
    this.getNoteById = this.getNoteById.bind(this);
    this.getNoteByTitle = this.getNoteByTitle.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.deleteNotes = this.deleteNotes.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  private handleMessage(event: MessageEvent): void {
    if (event.source !== window) return;
    if (event.data.type === 'ANNOTE_LISTENER_READY') {
      console.log('AppMessageDb: Content script listener is ready');
      // } else {
      //   console.log('AppMessageDb: Received message', event.data);
    }
  }

  private sendMessage<T>(message: DatabaseMessage): Promise<T> {
    return new Promise((resolve, reject) => {
      this.messageId += 1;
      const { messageId } = this;

      const listener = (event: MessageEvent): void => {
        // console.log('AM - received message', event.data);
        if (event.source !== window) return;
        if (event.data.messageType !== 'EXT_RESPONSE') return;
        if (event.data.messageId === messageId) {
          window.removeEventListener('message', listener);
          if (event.data.error) {
            console.log('AM - received error', event.data.error);
            reject(new Error(event.data.error));
          } else {
            // console.log('AM - received result', event.data.result);
            resolve(event.data.result);
          }
        }
      };

      window.addEventListener('message', listener);
      window.postMessage({ messageId, messageType: 'APP_MESSAGE', ...message }, '*');
    });
  }

  async getNotes(ids?: UUID[]): Promise<Note[]> {
    return this.sendMessage<Note[]>({ action: 'getNotes', ids });
  }

  async getNotesByUrl(url: string): Promise<{ notes: Note[]; isBlocked: boolean }> {
    return this.sendMessage<{ notes: Note[]; isBlocked: boolean }>({ action: 'getNotesByUrl', url });
  }

  async getNoteById(id: UUID): Promise<Note | null> {
    return this.sendMessage<Note | null>({ action: 'getNoteById', id });
  }

  async getNoteByTitle(title: string): Promise<Note | null> {
    return this.sendMessage<Note | null>({ action: 'getNoteByTitle', title });
  }

  async searchNotes(query: string, types?: TypeOfNote[], limit: number = 10): Promise<Note[]> {
    return this.sendMessage<Note[]>({ action: 'searchNotes', query, types, limit });
  }

  async updateNotes(notes: Note[]): Promise<void> {
    // filter out suggestions
    return this.sendMessage<void>({ action: 'updateNotes', notes });
  }

  async deleteNotes(notes: Note[]): Promise<void> {
    if (!notes || !Array.isArray(notes) || notes.length === 0) {
      throw new Error('Invalid notes array provided for deletion');
    }
    return this.sendMessage<void>({ action: 'deleteNotes', notes });
  }

  async getProfile(): Promise<Profile> {
    return this.sendMessage<Profile>({ action: 'getProfile' });
  }

  async updateProfile(profile: Profile): Promise<void> {
    return this.sendMessage<void>({ action: 'updateProfile', profile });
  }

  async fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    console.log('AppMessageDb fetch called:', {
      input,
      method: init?.method,
      headers: init?.headers,
      signal: init?.signal ? 'present' : 'absent',
    });

    try {
      // Convert input to string if it's a Request object
      const inputUrl = input instanceof Request ? input.url : input.toString();

      const jsonResponse: JSONResponse = await this.sendMessage<JSONResponse>({
        action: 'fetch',
        input: inputUrl,
        init: {
          ...init,
          // Ensure headers are properly serializable
          headers: init?.headers ? Object.fromEntries(new Headers(init.headers)) : undefined,
          // Remove signal as it can't be serialized
          signal: undefined,
        },
      });

      console.log('AppMessageDb fetch response received:', {
        status: jsonResponse.status,
        ok: jsonResponse.ok,
      });

      // repackage the jsonified response into an actual response
      const response = new Response(jsonResponse.body, {
        status: jsonResponse.status,
        statusText: jsonResponse.statusText,
        headers: new Headers(jsonResponse.headers),
      });

      // Workaround for the response object not allowing the correct properties
      Object.defineProperties(response, {
        url: { value: jsonResponse.url },
        redirected: { value: jsonResponse.redirected },
        ok: { value: jsonResponse.ok },
        type: { value: jsonResponse.type },
      });

      return response;
    } catch (error) {
      console.error('AppMessageDb fetch error:', error);
      throw error;
    }
  }
}

export default AppMessageDatabase;
