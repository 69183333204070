import React, { useContext, useMemo } from 'react';
import { Note } from 'core/types';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { SVGIcon } from 'src/components/Icon/Icon';
import { ReactComponent as PlusIcon } from 'src/components/Icon/icons/plus.svg';
import { ReactComponent as ShowBacklinksIcon } from 'src/components/Icon/icons/show_backlinks.svg';
import { ReactComponent as ShowLinksIcon } from 'src/components/Icon/icons/show_links.svg';
import { ReactComponent as ClearBoardIcon } from 'src/components/Icon/icons/clear_board.svg';
import { ReactComponent as TrashIcon } from 'src/components/Icon/icons/trash.svg';
import { MenuContainer, MenuItem, ICON_SIZE, MenuProps } from './MenuComponents';

const CardExtrasMenu: React.FC<MenuProps> = ({ note, closeMenu }) => {
  const { createTakeaway, deleteNotes } = useBrainContext();
  const { showAbeforeB, showAafterB, hide, shownIds } = useContext(ShownIdsContext);

  const createBacklink = (n: Note): void => {
    const newNote = createTakeaway(n);
    showAbeforeB(newNote.id, n.id);
    if (closeMenu) closeMenu();
  };

  const showBacklinks = (n: Note): void => {
    showAafterB(n.backlinks, n.id);
    if (closeMenu) closeMenu();
  };

  const showLinks = (n: Note): void => {
    showAbeforeB(n.links, n.id);
    if (closeMenu) closeMenu();
  };

  const hideNote = (n: Note): void => {
    hide(n.id);
    if (closeMenu) closeMenu();
  };

  const hideOthers = (n: Note): void => {
    hide(shownIds.filter((id) => id !== n.id));
    if (closeMenu) closeMenu();
  };

  const deleteNote = (n: Note): void => {
    // TODO: Add confirmation
    hide(n.id);
    deleteNotes([n]);
    if (closeMenu) closeMenu();
  };

  const isHideOthersDisabled = useMemo(() => {
    return shownIds.length === 1;
  }, [shownIds]);

  const isShowLinksDisabled = useMemo(() => {
    const hiddenLinks = note.links.filter((id) => !shownIds.includes(id));
    return hiddenLinks.length === 0;
  }, [note.links, shownIds]);

  const isShowBacklinksDisabled = useMemo(() => {
    const hiddenBacklinks = note.backlinks.filter((id) => !shownIds.includes(id));
    return hiddenBacklinks.length === 0;
  }, [note.backlinks, shownIds]);

  console.log('dropdown menu opened', note.id);
  return (
    <MenuContainer>
      <MenuItem onClick={() => createBacklink(note)}>
        <SVGIcon svgComponent={PlusIcon} size={ICON_SIZE} />
        New backlink note
      </MenuItem>
      <MenuItem disabled={isShowBacklinksDisabled} onClick={() => showBacklinks(note)}>
        <SVGIcon svgComponent={ShowBacklinksIcon} size={ICON_SIZE} />
        Show all backlinks
      </MenuItem>
      <MenuItem disabled={isShowLinksDisabled} onClick={() => showLinks(note)}>
        <SVGIcon svgComponent={ShowLinksIcon} size={ICON_SIZE} />
        Show all links
      </MenuItem>
      <MenuItem onClick={() => hideNote(note)}>
        <SVGIcon svgComponent={ClearBoardIcon} size={ICON_SIZE} />
        Hide this note
      </MenuItem>
      <MenuItem disabled={isHideOthersDisabled} onClick={() => hideOthers(note)}>
        <SVGIcon svgComponent={ClearBoardIcon} size={ICON_SIZE} />
        Hide all others
      </MenuItem>
      <MenuItem onClick={() => deleteNote(note)}>
        <SVGIcon svgComponent={TrashIcon} size={ICON_SIZE} />
        Delete
      </MenuItem>
    </MenuContainer>
  );
};

export default CardExtrasMenu;
