import React, { useCallback, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Takeaway } from 'core/types';
import { text, colors, dimensions } from 'core/styles';
import { ProseEditor } from 'src/components/ProseEditor';
import { useBrainContext } from 'src/context/BrainContext';
import { CardSize } from 'src/types';
import { ShownIdsContext } from 'src/context/ShownContext';

export const TITLE_PLACEHOLDER = 'Untitled Note';
const VALUE_PLACEHOLDER = 'Describe what you have found out';
const FOCUSED_PLACEHOLDER = ` Press '#' to link to a note or article`;

// TODO this wrapper could actually go in the card element?
const Wrapper = styled.div<{ size: CardSize }>`
  display: flex;
  flex-direction: column;
  /* pointer-events: none; */
  user-select: none;
  position: relative;
  width: ${({ size }) => (size === 'LARGE' ? dimensions.oneInchRem * 8.5 : dimensions.oneInchRem * 5)}rem;
  min-height: ${({ size }) => (size === 'LARGE' ? dimensions.oneInchRem * 11 : dimensions.oneInchRem * 3)}rem;
`;

const TitleRow = styled.div`
  font-family: ${text.family.primary};
  font-weight: ${text.weight.bold};
  display: flex;
  flex-direction: row;
  padding: 2rem 2rem 1rem;
  border-bottom: 1px solid ${colors.lines.indexRed};
  pointer-events: auto;
`;

const Title = styled.textarea`
  font-family: ${text.family.primary};
  font-weight: ${text.weight.medium};
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: ${colors.text.header};
  flex: 1;
  resize: 'vertical';

  border: none;
  resize: none;
  overflow: hidden;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;

  &:empty::before {
    content: attr(placeholder);
    color: ${colors.text.header};
  }
`;

const ValueRow = styled.div<{ size: CardSize }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0px;
  pointer-events: auto;
  font-weight: ${text.weight.regular};
  font-size: ${({ size }) => (size === 'LARGE' ? '16px' : '14px')};
  line-height: ${({ size }) => (size === 'LARGE' ? '24px' : '20px')};
  color: ${colors.text.primary};

  .ProseMirror {
    flex: 1;
    min-height: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.ProseMirror.empty::before {
    color: ${colors.text.placeholder};
    font-size: inherit;
  }
`;

const TakeawayCard: React.FC<{ takeaway: Takeaway; readOnly?: boolean }> = ({ takeaway, readOnly = false }) => {
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const { updateNotes } = useBrainContext();

  const { shownSizes } = useContext(ShownIdsContext);
  const size = readOnly ? 'SMALL' : shownSizes[takeaway.id] || 'SMALL';

  const debouncedUpdateNotes = useCallback(debounce(updateNotes, 500), [updateNotes]);

  const sizeTitle = useCallback(() => {
    if (!titleRef.current) return;
    titleRef.current.style.height = 'inherit';
    titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
  }, [titleRef]);

  const updateTitle = useCallback(() => {
    if (!titleRef.current) return;
    const title = titleRef.current.value;
    sizeTitle();

    if (title === takeaway.title) return;
    debouncedUpdateNotes([{ ...takeaway, title }]);
  }, [takeaway, titleRef, debouncedUpdateNotes]);

  // resize title on mount
  useEffect(() => {
    sizeTitle();
  }, [sizeTitle]);

  // update title ref if takeaway.title changes
  useEffect(() => {
    if (!titleRef.current) return;
    titleRef.current.value = takeaway.title;
  }, [takeaway.title]);

  useEffect(() => {
    console.log('takeaway updated', takeaway.title, takeaway.value);
  }, [takeaway]);

  return (
    <Wrapper size={size}>
      <TitleRow>
        <Title
          ref={titleRef}
          defaultValue={takeaway.title}
          contentEditable={!readOnly}
          rows={1}
          onChange={updateTitle}
          placeholder={TITLE_PLACEHOLDER}
          suppressContentEditableWarning
        />
      </TitleRow>
      <ValueRow size={size}>
        <ProseEditor
          note={takeaway}
          placeholder={VALUE_PLACEHOLDER}
          focusedPlaceholder={FOCUSED_PLACEHOLDER}
          readOnly={readOnly}
          isInline={false}
        />
      </ValueRow>
    </Wrapper>
  );
};

export default TakeawayCard;
