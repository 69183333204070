/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import markdownit from 'markdown-it';
import { MarkdownParser, MarkdownSerializer, MarkdownSerializerState } from 'prosemirror-markdown';
import { Node } from 'prosemirror-model';
import { schema } from './schema';

const md = markdownit();

md.disable('heading');
md.disable('hr'); // horizontal rule
md.disable('html_block');
md.disable('html_inline');
md.disable('fence'); // fenced code blocks
md.disable('autolink');
md.disable('strikethrough');
md.disable('table');
md.disable('image');
md.disable('code');
md.disable('blockquote');
md.disable('fence');

// Loosely define the StateInline type if specific typing isn't available
interface StateInline {
  src: string;
  pos: number;
  posMax: number;
  md: markdownit; // Reference to the MarkdownIt instance
  tokens: any[];

  push(type: string, tag: string, nesting: number): any;
}

// make this custom "internal_links" function run before the default "link" function
md.inline.ruler.before('link', 'internal_links', (state: StateInline, silent: boolean): boolean => {
  const start = state.pos;
  if (state.src.charAt(start) !== '[') return false;

  // The regex captures [displayText][noteId]
  const match = state.src.slice(start).match(/^\[([^\]]+)\]\[([^\]]+)\]/);
  if (!match) return false;

  // Calculate the length of the full match to update state.pos later
  const fullMatchLength = match[0].length;

  // Avoid processing if we're only testing for validity or skip token generation
  if (!silent) {
    // Extract display text and note ID from the match
    const displayText = match[1];
    const noteId = match[2];

    // Add 'link_open' token with href and internal attributes
    let token = state.push('link_open', 'a', 1);
    token.attrs = [
      ['href', noteId],
      ['title', displayText],
      ['internal', 'true'],
    ];

    // Add 'text' token for the link's display text
    token = state.push('text', '', 0);
    token.content = displayText;

    // Add 'link_close' token
    state.push('link_close', 'a', -1);
  }

  // Move the state position past the entire match
  state.pos += fullMatchLength;
  return true;
});

const parser = new MarkdownParser(schema, md, {
  // Core supported tokens
  paragraph: { block: 'paragraph' },
  text: { node: 'text' },
  em: { mark: 'em' },
  strong: { mark: 'strong' },
  link: {
    mark: 'link',
    getAttrs: (token) => ({
      href: token.attrGet('href'),
      title: token.attrGet('title'),
      internal: token.attrGet('internal') === 'true',
    }),
  },
  ordered_list: { block: 'ordered_list' },
  bullet_list: { block: 'bullet_list' },
  list_item: { block: 'list_item' },

  // Instead of converting to text nodes, we'll ignore these tokens
  code: { ignore: true },
  code_inline: { ignore: true },
  code_block: { ignore: true },
  blockquote: { ignore: true },
  hard_break: { ignore: true },
});

const escape = (text: string): string => {
  return text.replace(/[\\`*_{}[\]()#+-.!]/g, '\\$&'); // Escapes markdown special characters
};

const serializeLink = (state: MarkdownSerializerState, node: Node): void => {
  if (node.attrs.internal) {
    state.write(`[${escape(node.textContent)}][${node.attrs.href}]`);
  } else {
    state.write(`[${escape(node.textContent)}](${escape(node.attrs.href)})`);
  }
};

const serializers = {
  // Add serializers for other nodes as needed
  paragraph(state: MarkdownSerializerState, node: Node) {
    state.renderInline(node);
    state.closeBlock(node);
  },

  text(state: MarkdownSerializerState, node: Node) {
    state.text(node.text || '');
  },

  link: serializeLink,
  bullet_list(state: MarkdownSerializerState, node: Node) {
    state.renderList(node, '  ', () => '- ');
  },
  ordered_list(state: MarkdownSerializerState, node: Node) {
    state.renderList(node, '  ', (i) => `${i + 1}. `);
  },
  list_item(state: MarkdownSerializerState, node: Node) {
    state.renderContent(node);
    state.closeBlock(node);
  },
};

const marks = {
  em: {
    open: '*',
    close: '*',
    mixable: true,
    expelEnclosingWhitespace: true,
  },
  strong: {
    open: '**',
    close: '**',
    mixable: true,
    expelEnclosingWhitespace: true,
  },
  link: {
    open(_state: MarkdownSerializerState) {
      return '[';
    },
    close(state: MarkdownSerializerState, mark: any) {
      const { href } = mark.attrs;
      if (mark.attrs.internal) return `][${href}]`;
      return `](${href})`;
    },
  },
};

const serializer = new MarkdownSerializer(serializers, marks);

export { parser, serializer };
