// Toolbar.tsx
import styled from 'styled-components';
import { useContext } from 'react';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { colors } from 'core/styles';
import { ReactComponent as ClearBoardIcon } from 'src/components/Icon/icons/clear_board.svg';
import { ReactComponent as ArchiveIcon } from 'src/components/Icon/icons/archive.svg';
import { SVGIcon } from './Icon/Icon';
import NewCardButton from './NewCardButton';
import Library from './Library';

const ToolbarWrapper = styled.div<{ isToolbarExpanded: boolean }>`
  position: sticky;
  display: flex;
  z-index: 25;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 4rem;
  position: sticky;
  transform: ${(props) => (props.isToolbarExpanded ? 'translateX(0)' : 'translate(-41rem)')};
  margin-right: ${(props) => (props.isToolbarExpanded ? '0' : '-41rem')};
  transition: transform 0.3s ease-in-out, margin-right 0.3s ease-in-out;
`;

// The left shaddow of the toolbar buttons needs to be removed
// this is a box over it
const ToolbarShadowOverlay = styled.div`
  position: absolute;
  top: 0;
  left: -2px;
  width: 3px;
  height: 100%;
  background: inherit;
  z-index: 26;
`;

const ToolbarElementWrapper = styled.div`
  position: absolute;
  right: 0;
  transform: translateX(100%);
`;

const ToggleWrapper = styled(ToolbarElementWrapper)`
  top: 0;
  z-index: 21;
`;

const NewCardWrapper = styled(ToolbarElementWrapper)`
  top: 5rem;
`;

const BottomWrapper = styled(ToolbarElementWrapper)`
  bottom: 0;
`;

const ToolbarButton = styled.div`
  display: flex;
  padding: 1rem 1rem;
  width: 2rem;
  margin-left: 1rem;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
  background: ${colors.bg.library};
  box-shadow: ${colors.shadow.toolbar};
  cursor: pointer;
`;

const LibraryButton = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  height: 3rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  background: ${colors.bg.library};
  box-shadow: ${colors.shadow.toolbar};
  cursor: pointer;
  z-index: 21;
  position: relative;
`;

const StyledNewCardButton = styled(NewCardButton)`
  z-index: 10;
`;

interface ToolbarProps {
  isToolbarExpanded: boolean;
  toggleToolbar: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({ isToolbarExpanded, toggleToolbar }) => {
  const { createTakeaway } = useBrainContext();
  const { show, clear } = useContext(ShownIdsContext);

  // State for Library
  const newNote = async (): Promise<void> => {
    const createdNote = await createTakeaway();
    show(createdNote.id);
  };

  return (
    <ToolbarWrapper isToolbarExpanded={isToolbarExpanded} id="toolbar">
      <Library />
      <ToggleWrapper>
        <LibraryButton onClick={toggleToolbar}>
          <ToolbarShadowOverlay />
          <SVGIcon svgComponent={ArchiveIcon} size="2rem" />
        </LibraryButton>
      </ToggleWrapper>
      <NewCardWrapper>
        <StyledNewCardButton onClick={newNote} />
      </NewCardWrapper>
      <BottomWrapper>
        <ToolbarButton onClick={clear}>
          <SVGIcon svgComponent={ClearBoardIcon} size="2rem" />
        </ToolbarButton>
      </BottomWrapper>
    </ToolbarWrapper>
  );
};
export default Toolbar;
